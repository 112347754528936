import {
  Button, Descriptions, Divider, Edit,
  Empty,
  Form, Icons, RefreshButton, SaveButton, ShowButton, Space, Steps, Typography, useStepsForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { StateButtons } from "components/common/stateButtons";
import { RenderGroup } from "components/evaluation/renderGroup";
import { CancelButton } from "components/utils/cancelButton";
import { DisplayScore } from "components/utils/displayScore";
import { LookupButton } from "components/utils/lookupButton";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IBaseProps, IEvaluation, IEvaluationModel } from "interfaces";
import { calculateScore, DATAPROVIDER_DEFAULT, RESOURCE_PATH } from "scripts/site";

export const EvaluationEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    current,
    gotoStep,
    stepsProps,
    formProps,
    saveButtonProps,
    queryResult,
    submit
  } = useStepsForm<IEvaluation>({
    dataProviderName: DATAPROVIDER_DEFAULT,
    isBackValidate: true,
    submitOnEnter: true,
    redirect: false
  });

  const record = queryResult?.data?.data;

  return (
    <Edit {...saveButtonProps}
      pageHeaderProps={{
        extra: <>
          <ShowButton></ShowButton>
          <RefreshButton></RefreshButton>
        </>,
        title: "Evaluation",
        className: "frm-wt-st-btn"
      }}
      isLoading={queryResult?.isFetching}
      actionButtons={
        <>
          {
            queryResult?.isFetching ? "" :
              <Space className="stt-frm-btn-st" wrap align="start">
                <StateButtons {...record as IBaseProps}
                  resourceToAct={RESOURCE_PATH.EVALUATION}
                  resourceToActId={record?.id || "0"}
                  navigateToId={(record?.engagementId || "0")}
                  resourceToNavigate={RESOURCE_PATH.ENGAGEMENTS}
                  hideActions={['reassign']}
                  form={formProps.form}
                />
              </Space>
          }
          <Space className="stt-frm-btn-frm" wrap align="end">
            <Button
              disabled={!(current > 0)}
              onClick={() => {
                gotoStep(current - 1);
              }}
            >
              <Icons.LeftOutlined />
              Previous
            </Button>
            <Button
              disabled={!(current < (record?.evaluationModel?.groups.length || 1) - 1)}
              onClick={() => {
                gotoStep(current + 1);
              }}
            >
              Next
              <Icons.RightOutlined />
            </Button>
            <SaveButton {...saveButtonProps} onClick={() => submit()} />
            <CancelButton />
          </Space>
        </>
      }
    >
      {
        queryResult?.isFetching ? "" :
          <>
            <Divider />
            <Descriptions style={{ marginBottom: 25 }} labelStyle={{ fontWeight: "bold" }} size="small">
              <Descriptions.Item label="Engagement">
                <LookupButton id={record?.engagementId || ""} resource={RESOURCE_PATH.ENGAGEMENTS}></LookupButton>
              </Descriptions.Item>
              <Descriptions.Item label="Evaluation Model">
                <LookupButton id={record?.evaluationModelId || ""} resource={RESOURCE_PATH.EVALUATIONMODEL}></LookupButton>
              </Descriptions.Item>
              <Descriptions.Item label={record?.evaluationModel?.ui.fieldLabel}>
                <DisplayScore
                  ui={record?.evaluationModel?.ui || "" as any}
                  output={record?.evaluationModel?.output || "" as any}
                />
              </Descriptions.Item>
              <Descriptions.Item label="">
                <ObjectMetadata {...record as IBaseProps} />
              </Descriptions.Item>
            </Descriptions>
            <Divider />
          </>
      }

      <Steps responsive {...stepsProps} percent={((current + 1) / (record?.evaluationModel.groups.length || 1)) * 100}
      >
        {record?.evaluationModel?.groups
          .sort(g => g.order)
          .map((g, i) => {
            return (
              <Steps.Step key={g.id} title={g.name}
                description={
                  record?.evaluationModel?.ui.displayCategoryScores ?
                    <>
                      <Typography.Text strong>Score: </Typography.Text>
                      <DisplayScore
                        override={true}
                        overrideScore={(record?.evaluationModel?.groups[i].score || g.score || 0)}
                        ui={record?.evaluationModel?.ui}
                        output={record?.evaluationModel?.output}
                      />
                    </>
                    : ""
                } />
            );
          })}
      </Steps>
      <Form {...formProps} autoComplete="off"
        layout="vertical"
      >
        {record
          ?
          <Form.Item
            name={["evaluationModel"]}
            rules={[
              {
                validator: (_rule, value: IEvaluationModel) => {
                  calculateScore(value);
                  return Promise.resolve();
                }
              }
            ]}
          >
            <RenderGroup {...record.evaluationModel.groups[current]} index={current} />
          </Form.Item>
          : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
      </Form>
    </Edit >
  );
};
