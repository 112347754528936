import { ILookup } from "@architecture-innovation-transformation/lib-common";
import { Form, FormInstance, Radio, Spin, useRadioGroup } from "@pankod/refine-antd";
import { ICase } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const CaseFieldsRegionRadio: React.FC<{ record?: ICase, isLoading: boolean, form: FormInstance }> = ({ record, isLoading, form }) => {

    const { radioGroupProps: regionProps, queryResult: regionQuery } = useRadioGroup<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.REGION,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],

    });
    const [region, setRegion] = useState(record?.regionId);

    const { radioGroupProps: subsidiaryProps, queryResult: subsidiaryQuery } = useRadioGroup<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.SUBSIDIARY,
        optionLabel: "name",
        optionValue: "id",
        filters: [{
            field: "parentId",
            operator: "eq",
            value: region
        }],
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
    });
    const [subsidiary, setSubsidiary] = useState(record?.subsidiaryId ?? "");

    const { radioGroupProps: countryProps, queryResult: countryQuery } = useRadioGroup<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.COUNTRY,
        optionLabel: "name",
        optionValue: "id",
        filters: [{
            field: "parentId",
            operator: "eq",
            value: subsidiary
        }],
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
    });
    const [country, setCountry] = useState(record?.countryId ?? "");




    const [isInitialLoad, setIsInitialLoad] = useState(true);
    if (isInitialLoad && !isLoading && record) {
        setIsInitialLoad(false);
        setRegion(record?.regionId);
        setSubsidiary(record?.subsidiaryId);
        setCountry(record?.countryId);
    }

    const ClearFormBelow = (rank: number) => {
        let indexRank: number = 1;

        if (rank < indexRank) {
            setRegion("");
            form.setFieldsValue({ "regionId": null });
        }
        indexRank++;

        if (rank < indexRank) {
            setSubsidiary("");
            form.setFieldsValue({ "subsidiaryId": null });
        }
        indexRank++;

        if (rank < indexRank) {
            setCountry("");
            form.setFieldsValue({ "countryId": null });
        }
        indexRank++;

    }

    return (
        <>
            <Spin spinning={regionQuery.isRefetching || regionQuery.isLoading} className="field-loading-spinner">
                <Form.Item
                    label="Region"
                    name="regionId"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            validator(_rule, val) {
                                if (val !== "") {
                                    if (region) {
                                        return Promise.resolve();
                                    }
                                    else {
                                        return Promise.reject("Please select a value");
                                    }
                                }
                                else {
                                    return Promise.reject();
                                }
                            },
                        }
                    ]}
                >
                    <Radio.Group
                        optionType="button"
                        buttonStyle="solid"


                        {...regionProps}
                        onChange={(event) => {
                            setRegion(event.target.value);
                            ClearFormBelow(1);
                        }}
                    />
                </Form.Item>
            </Spin>

            {region &&
                <Spin spinning={subsidiaryQuery.isRefetching || subsidiaryQuery.isLoading} className="field-loading-spinner">
                    <Form.Item
                        label="Subsidiary"
                        name="subsidiaryId"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                validator(_rule, val) {
                                    if (val !== "") {
                                        if (subsidiary) {
                                            return Promise.resolve();
                                        }
                                        else {
                                            return Promise.reject("Please select a value");
                                        }
                                    }
                                    else {
                                        return Promise.reject();
                                    }
                                },
                            }
                        ]}
                    >
                        <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            {...subsidiaryProps}
                            onChange={(event) => {
                                setSubsidiary(event.target.value);
                                ClearFormBelow(2);
                            }}
                        />
                    </Form.Item>
                </Spin>
            }

            {subsidiary &&
                <Spin spinning={countryQuery.isRefetching || countryQuery.isLoading} className="field-loading-spinner">
                    <Form.Item
                        label="Country"
                        name="countryId"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                validator(_rule, val) {
                                    if (val !== "") {
                                        if (country) {
                                            return Promise.resolve();
                                        }
                                        else {
                                            return Promise.reject("Please select a value");
                                        }
                                    }
                                    else {
                                        return Promise.reject();
                                    }
                                },
                            }
                        ]}
                    >
                        {!(countryQuery.isRefetching || countryQuery.isLoading) &&
                            <Radio.Group
                                optionType="button"
                                buttonStyle="solid"
                                {...countryProps}
                                onChange={(event) => {
                                    setCountry(event.target.value);
                                    ClearFormBelow(3);
                                }}
                            />}
                    </Form.Item>
                </Spin>
            }
        </>

    );
}