import { Table, Tag } from "@pankod/refine-antd";
import { ITags } from "interfaces";

export declare type mdProps = {
    tagsList: ITags[] | undefined;
};

export const RenderTagsTable: React.FC<mdProps> = ({
    tagsList
}) => {
    return (
        <Table dataSource={tagsList}>
            <Table.Column title="Tag" dataIndex="TagName" key="tagname" />
            <Table.Column title="Tag Type" dataIndex="TagType" key="tagtype" />
            <Table.Column
                title="Suggested Tags"
                dataIndex="Suggestions"
                key="suggestions"
                render={(Suggestions: string[]) => (
                    <>
                        {Suggestions.map(tag => (
                            <Tag color="blue" key={tag.toLowerCase()}>
                                {tag}
                            </Tag>
                        ))}
                    </>
                )}
            />
        </Table>
    );
};