import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Divider, Icons, Show, Tabs, Tag } from "@pankod/refine-antd";
import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { DisplayBasicProfile } from "components/profile/displayBasicProfile";
import { DisplayDetailedProfile } from "components/profile/displayDetailedProfile";
import { DisplayMoreTab } from "components/profile/displayMoreTab";
import { DisplayRnRTab } from "components/profile/displayRnRTab";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IBaseProps, IUser } from "interfaces";
import { DATAPROVIDER_DEFAULT } from "scripts/site";

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IUser>({
    dataProviderName: DATAPROVIDER_DEFAULT
  });
  const { data, isLoading } = queryResult;
  const record = data?.data as IUser;

  return (
    <Show isLoading={isLoading}
      title={record?.name}
      pageHeaderProps={{
        tags: record?.stateManager?.state ? <Tag>{stringExtensions.capitalize(record?.stateManager?.state)}</Tag> : <></>,
      }}
    >
      <Tabs>
        <Tabs.TabPane key="info" tab={<><Icons.IdcardOutlined />Info</>}>
          {record ? <DisplayBasicProfile {...record}></DisplayBasicProfile> : <></>}
          {record ? <DisplayDetailedProfile {...record}></DisplayDetailedProfile> : <></>}
        </Tabs.TabPane>
        {
          record?.employee &&
          <>
            <Tabs.TabPane key="randr" tab={<><Icons.StarOutlined />Rewards and Recognition</>}>
              {record ? <DisplayRnRTab {...record.employee}></DisplayRnRTab> : <></>}
            </Tabs.TabPane>
            <Tabs.TabPane key="more" tab={<><Icons.HolderOutlined />More</>}>
              {record ? <DisplayMoreTab {...record.employee}></DisplayMoreTab> : <></>}
            </Tabs.TabPane>
          </>
        }
      </Tabs>
      <Divider></Divider>
      <div style={{ marginTop: 15 }}>
        <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
      </div>
    </Show>
  );
};
