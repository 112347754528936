import { arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Checkbox, DatePicker, Edit, Form, Input, Radio, SaveButton, Select, Tag, Typography, useCheckboxGroup, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useMany } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import dayjs from "dayjs";
import { ILookup, IRole, IUser } from "interfaces";
import { UserType } from "interfaces/enum";
import { DATAPROVIDER_DEFAULT, DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, id, queryResult } = useForm<IUser>({
    dataProviderName: DATAPROVIDER_DEFAULT,
  });

  const { data: editUserRoleLevels } = useMany<IRole>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ROLE,
    ids: queryResult?.data?.data.roleIds ?? [],
    queryOptions: {
      enabled: (queryResult?.data?.data.roleIds ?? []).length > 0,
    },
  });

  const editLevels = editUserRoleLevels ? (editUserRoleLevels.data.map(r => r.level ?? 0) as number[]) : [0];
  const editUserMax = Array.isArray(editLevels) ? Math.max(...editLevels) : 0;

  const { data: userData } = useGetIdentity();
  const record = userData as IUser;

  const { data: currentUserRoleLevels, isLoading: currentRoleLoading } = useMany<IRole>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ROLE,
    ids: record?.roleIds,
    queryOptions: {
      enabled: record?.roleIds.length > 0,
    },
  });

  const currentLevels = currentUserRoleLevels ? (currentUserRoleLevels.data.map(r => r.level ?? 0) as number[]) : [0];
  const currentUserMax = Array.isArray(currentLevels) ? Math.max(...currentLevels) : 0;

  const allowRoleChange = editUserMax <= currentUserMax;

  const userType = queryResult?.data?.data?.userType;

  const { queryResult: roleResult } = useCheckboxGroup<IRole>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ROLE,
    optionLabel: "name",
    optionValue: "id",
    sort: [
      {
        field: "level",
        order: "asc"
      }
    ],
    filters: [{
      field: "level",
      operator: "lte",
      value: currentUserMax
    },
    {
      field: "applicableUserTypes",
      operator: "contains",
      value: userType
    },
    {
      field: "_arrayfields",
      operator: "eq",
      value: "applicableUserTypes"
    }],
    queryOptions: {
      enabled: (!!userType) && allowRoleChange,
    },
    fetchSize: 50
  });

  const { selectProps: userProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PROFILE,
    optionLabel: "nameEmail",
    optionValue: "id",
    filters: [{
      field: "id",
      operator: "ne",
      value: id
    }],
    sort: [
      {
        field: "lastAccessedAt",
        order: "desc"
      },
    ],
    queryOptions: {
      enabled: userType === UserType.Employee
    }
  });

  const groupedRole = arrayExtensions.groupBy((roleResult.data?.data ?? []).map(r => {
    r.groupProp = r.ui.color;
    return r;
  }), "groupProp");

  const todaysDate = dayjs().add(1, "day");
  if (queryResult?.data?.data?.employee?.screeningDate) {
    queryResult.data.data.employee.screeningDate = dayjs(queryResult?.data?.data?.employee?.screeningDate);
  }

  if (queryResult?.data?.data?.employee) {
    queryResult.data.data.employee.screeningType = "HireRight";
  }

  return (
    <Edit //saveButtonProps={saveButtonProps}
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
          <CancelButton />
        </>
      )}
    // actionButtons={
    //   <>
    //     <SaveButton {...saveButtonProps} />
    //     <CancelButton />
    //   </>
    // }
    >
      <Form {...formProps} autoComplete="off"
        //initialValues={{ employee: { screeningType: "HireRight" } }}
        layout="vertical">

        <Form.Item
          label="Email"
          name="id"
          valuePropName="children"
        >
          <Typography.Text copyable></Typography.Text>
        </Form.Item>

        <Form.Item
          label="User Type"
          name="userType"
        >
          <Typography.Text>{stringExtensions.capitalize(userType ?? "")}</Typography.Text>
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              max: 100
            },
          ]}
        >
          <Input />
        </Form.Item>

        {!currentRoleLoading
          && (roleResult?.data?.data?.length ?? 0) > 0
          && (allowRoleChange
            ? <Form.Item
              label="Roles"
              name="roleIds"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  type: "array"
                },
              ]}
            >
              <Checkbox.Group>
                {
                  Object.keys(groupedRole).map((k, ind) =>
                    <div key={ind}>
                      {(groupedRole[k] as IRole[]).map((r, i) => (
                        <Checkbox key={i} value={r.id} style={{ marginLeft: 0 }} className="role-checkbox" >
                          <Tag color={r.ui?.color || "blue"}>{r.name || ""}</Tag>
                        </Checkbox>
                      ))}
                    </div>)
                }
              </Checkbox.Group>
            </Form.Item>
            :
            <Form.Item
              label="Roles"
            //name="roleIds"
            >
              {
                editUserRoleLevels?.data
                  ? arrayExtensions.sortByName(editUserRoleLevels?.data).map((r, i) => (
                    <Tag key={i} color={r.ui?.color || "blue"}>{r.name || ""}</Tag>
                  ))
                  : queryResult?.data?.data?.roleIds.map((r, i) => (
                    <Tag key={i} color={"blue"}>{r || ""}</Tag>
                  ))
              }
              <br />
              <Typography.Text type="danger">You don't have sufficient privileges to change the Role of the User</Typography.Text>
            </Form.Item>)
        }

        {userType === UserType.Employee &&
          <>
            <Form.Item
              label="TCS Manager"
              name="managerUserId"
              rules={[
                {
                  required: true,
                  whitespace: true,
                },
              ]}
            >
              <Select placeholder="Search Name or Email" {...userProps} allowClear />
            </Form.Item>
            <Form.Item
              label="Screening Type"
              name={["employee", "screeningType"]}
              rules={[
                {
                  required: false,
                  whitespace: true,
                },
              ]}
            >
              <Input disabled />
              {/* <Radio.Group buttonStyle="solid" defaultValue="HireRight" value="HireRight">
                <Radio.Button value="HireRight">HireRight</Radio.Button>
              </Radio.Group> */}
            </Form.Item>

            <Form.Item
              label="Screening Id"
              name={["employee", "screeningId"]}
              tooltip={"Provide BGC Screening Id"}
              rules={[
                {
                  required: false,
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Screening Date"
              name={["employee", "screeningDate"]}
              tooltip="BGC Screening completion date"
              rules={[
                {
                  required: false,
                  whitespace: true,
                  type: "date"
                },
              ]}
            >
              <DatePicker picker="date" style={{ width: 280 }}
                disabledDate={(selDate) => selDate && selDate > todaysDate} />
            </Form.Item>
            <Form.Item
              label="Hire Source"
              name={["employee", "hireSource"]}
              rules={[
                {
                  required: false,
                  whitespace: true,
                },
              ]}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="Local">Local</Radio.Button>
                <Radio.Button value="Expat">Expat</Radio.Button>
                <Radio.Button value="Trainee">Trainee</Radio.Button>
                <Radio.Button value="BA">BA</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </>
        }

        <Form.Item
          label="Linked Users"
          name="linkedUserIds"
          rules={[
            {
              type: "array",
            },
          ]}
        >
          <Select placeholder="Search Name or Email" mode="multiple" {...userProps} allowClear />
        </Form.Item>
      </Form>
    </Edit>
  );
};
