import { List, Tabs } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { RenderTagsTable } from "components/manage/tagsTable";
import { ITagsReview } from "interfaces";

export const TagsReviewList: React.FC<IResourceComponentsProps> = () => {

    const { data: tagsData, isLoading } = useCustom<ITagsReview>({
        url: `${process.env.REACT_APP_TagsReviewEndpoint}`,
        method: "get",
    });

    const suggestionList = tagsData?.data.AISuggestions;
    const tagsType = suggestionList?.map(item => item.TagType).filter((value, index, self) => self.indexOf(value) === index);
    console.log(suggestionList);
    return (
        isLoading ?
            <RenderSpinner message="Loading Tags..." />
            :
            <List title="Review user added tags">
                <Tabs tabPosition="top">
                    {tagsType && tagsType.map((m, i) => (
                        <Tabs.TabPane key={i} tab={m}>
                            <RenderTagsTable
                                tagsList={suggestionList?.filter(item => item.TagType === m)}
                            />
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </List>

    );
};
