import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Divider, EditButton, Icons, Show, Tabs, Tag } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useNavigation } from "@pankod/refine-core";
import { DisplayAuthZ } from "components/profile/displayAuthZ";
import { DisplayBasicProfile } from "components/profile/displayBasicProfile";
import { DisplayDetailedProfile } from "components/profile/displayDetailedProfile";
import { DisplayMoreTab } from "components/profile/displayMoreTab";
import { DisplayRnRTab } from "components/profile/displayRnRTab";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IBaseProps, IUser } from "interfaces";
import { useLocation } from "react-router-dom";

export const ProfileList: React.FC<IResourceComponentsProps> = () => {
  const { data: userData, isLoading } = useGetIdentity();
  const record = userData as IUser;
  const { push } = useNavigation();
  const { pathname } = useLocation();

  return (
    <Show
      isLoading={isLoading}
      canDelete={false}
      pageHeaderProps={{
        backIcon: false,
        title: record?.name,
        tags: record?.stateManager?.state ? <Tag color={"green"}>{stringExtensions.capitalize(record?.stateManager?.state)}</Tag> : <></>,
        extra: <EditButton recordItemId={record?.id}>Edit Profile</EditButton>
      }} >

      <Tabs
        //reset the table filters on navigation
        onChange={() => {
          push(pathname)
        }}
      >
        <Tabs.TabPane key="info" tab={<><Icons.IdcardOutlined />Info</>}>
          {record ? <DisplayBasicProfile {...record} isCurrentUser></DisplayBasicProfile> : <></>}
          {record ? <DisplayDetailedProfile {...record}></DisplayDetailedProfile> : <></>}

          <Divider></Divider>
          <div style={{ marginTop: 15 }}>
            <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
          </div>
        </Tabs.TabPane>
        {record?.employee &&
          <>
            <Tabs.TabPane key="randr" tab={<><Icons.StarOutlined />Rewards and Recognition</>}>
              {record ? <DisplayRnRTab {...record.employee}></DisplayRnRTab> : <></>}
              <Divider></Divider>
              <div style={{ marginTop: 15 }}>
                <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane key="more" tab={<><Icons.HolderOutlined />More</>}>
              {record ? <DisplayMoreTab {...record.employee}></DisplayMoreTab> : <></>}
              <Divider></Divider>
              <div style={{ marginTop: 15 }}>
                <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
              </div>
            </Tabs.TabPane>
          </>
        }
        <Tabs.TabPane key="permissions" tab={<><Icons.SafetyOutlined />Permissions</>}>
          <DisplayAuthZ></DisplayAuthZ>
        </Tabs.TabPane>
      </Tabs>
    </Show>
  );
};
