export enum UserType {
    Employee = "employee",
    Client = "client",
    System = "system"
}

export enum GroupType {
    Static = "static",
    Dynamic = "dynamic"
}
