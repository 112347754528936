import { Col, Divider, Row, Typography } from "@pankod/refine-antd";
import dayjs from "dayjs";
import { IUserEmployee } from "interfaces";
const { Title, Text } = Typography;

export const DisplayMoreTab: React.FC<IUserEmployee> = (record: IUserEmployee) => {

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xl={12} xs={24}>
                    <Divider orientation="left" dashed >TCS Identifiers</Divider>
                    <Title level={5}>TCS Employee ID</Title>
                    <Text>{record?.employeeId || "---"}</Text>

                    <Title level={5}>TCS Card number</Title>
                    <Text>{record?.cardNumber || "---"}</Text>

                    <Title level={5}>Working From</Title>
                    <Text>{record?.workingFrom || "---"}</Text>

                    <Title level={5}>Building Access</Title>
                    <Text>{record?.buildingAccess || "---"}</Text>

                    <Title level={5}>Desk</Title>
                    <Text>{record?.desk || "---"}</Text>

                    {
                        record?.daysInOffice && <>
                            <Title level={5}>Days in Office</Title>
                            <Text>{record?.daysInOffice.join(", ")}</Text>
                        </>
                    }
                    <Title style={{ marginTop: 15 }} level={5}>Hire Source</Title>
                    <Text>{record?.hireSource || "---"}</Text>

                    <Title level={5}>Alternate Email</Title>
                    <Text>{record?.alternateEmail || "---"}</Text>
                </Col>
                <Col xl={12} xs={24}>

                    <Divider orientation="left" dashed>Background Check</Divider>

                    <Title level={5}>Screening Type</Title>
                    <Typography.Text>{record?.screeningType ?? "---"}</Typography.Text>

                    <Title level={5}>Screening Id</Title>
                    <Typography.Text>{record?.screeningId ?? "---"}</Typography.Text>

                    <Title style={{ marginTop: 15 }} level={5}>Screening Completion Date</Title>
                    {record?.screeningDate ? dayjs(record.screeningDate).format("LL") : "---"}

                    <Divider orientation="left" dashed>Vehicle</Divider>
                    <Title level={5}>Vehicle Make/Model</Title>
                    <Text>{record?.vehicleMakeModel || "---"}</Text>

                    <Title level={5}>Vehicle Color</Title>
                    <Text>{record?.vehicleColor || "---"}</Text>

                    <Title level={5}>Vehicle License Plate Number</Title>
                    <Text>{record?.vehicleLicense || "---"}</Text>
                </Col>
            </Row>
        </>
    );
};