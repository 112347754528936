import {
  Button,
  DateField,
  FilterDropdownProps, getDefaultSortOrder, Icons, Input, List, notification, Table, Tag, TextField, Typography, UrlField, useTable
} from "@pankod/refine-antd";
import { CrudFilter, CrudFilters, useNavigation, useOne, useResourceWithRoute, useUpdate } from "@pankod/refine-core";
import { AppFilterDropdown } from "components/common/AppFilterDropdown";
import { EngagementSubMenu } from "components/engagement/subMenu";
import { ShowUserCard } from "components/profile/showUserCard";
import { CancelButton } from "components/utils/cancelButton";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { IContract, ILookup } from "interfaces";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { DATAPROVIDER_DEFAULT, DATAPROVIDER_LOOKUP, getQueryStringParams, RESOURCE_PATH } from "scripts/site";
import { ShowContract } from "./showContract";

export const MappingContract: React.FC<{
  mode: "Add" | "Remove"
}> = ({ mode }) => {

  const { push } = useNavigation();
  const { search } = useLocation();
  const { engagementId } = getQueryStringParams(search);

  const assignedFilter: CrudFilter[] = (mode === "Add")
    ? [{
      field: "_nullfields",
      operator: "eq",
      value: "engagementId"
    }]
    : (engagementId ? [{
      field: "engagementId",
      operator: "eq",
      value: engagementId
    }] : []);

  const initialFilters: CrudFilters = [{
    field: "name",
    operator: "contains",
    value: ""
  },
  {
    field: "state",
    operator: "contains",
    value: ""
  },
  {
    field: "businessGroup",
    operator: "contains",
    value: ""
  },
  {
    field: "company",
    operator: "contains",
    value: ""
  },
  {
    field: "brm",
    operator: "contains",
    value: ""
  },
  {
    field: "deliveryHead",
    operator: "contains",
    value: ""
  }];

  const { tableProps, sorter, tableQueryResult } = useTable<IContract>({
    dataProviderName: DATAPROVIDER_DEFAULT,
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc"
      },
    ],

    initialFilter: initialFilters,
    permanentFilter: assignedFilter,
    syncWithLocation: false
  });
  const defaultOnChange = tableProps?.onChange ?? ((p, f, s, e) => { });

  const { data: enggName } = useOne<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ENGAGEMENTS,
    id: engagementId,
    queryOptions: {
      enabled: !!engagementId
    },
  });

  const resourceWithRoute = useResourceWithRoute();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const hasSelected = selectedRowKeys.length > 0;
  const { mutate, isLoading: updateIsLoading } = useUpdate<IContract>();

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const updateSelectedItems = () => {
    selectedRowKeys.forEach((contractId) => {
      mutate({
        resource: RESOURCE_PATH.CONTRACT,
        id: contractId,
        values: {
          engagementId: (mode === "Add") ? engagementId : null,
        },
        successNotification: false
      });
    })
    setSelectedRowKeys([]);
    notification.success({ key: "notificationKey", message: "Successful", description: `Successfully ${mode === "Add" ? "mapped" : "unmapped"} contracts`, type: "success" });
  };
  const totalContracts = tableQueryResult?.data?.total ?? 0;

  return (
    <div className="">
      <List
        title={enggName?.data?.name}
        pageHeaderProps={{
          extra: (mode === "Add")
            ? <>
              <Button
                type="primary"
                icon={<Icons.PlusOutlined />}
                onClick={updateSelectedItems}
                disabled={!hasSelected}
                loading={updateIsLoading}
              >
                Map to Engagement
              </Button>
              <CancelButton icon={<Icons.CheckOutlined />}>Done</CancelButton>
            </>
            : <>
              <Button
                icon={<Icons.PlusOutlined />}
                onClick={() => push(`/${resourceWithRoute(RESOURCE_PATH.CONTRACT).route}/edit?engagementId=${engagementId}`)}
              >
                Map Contracts
              </Button>
              <Button
                danger
                icon={<Icons.DeleteOutlined />}
                onClick={updateSelectedItems}
                disabled={!hasSelected}
                loading={updateIsLoading}
              >
                Remove Mapping
              </Button>
            </>,

          //tags: <Tag>{(tableQueryResult?.data?.total ?? 0) + " Contracts"}</Tag>,
          tags: <Tag color={"default"}>
            {tableQueryResult.isLoading ? "Loading" : totalContracts} Contract{totalContracts !== 1 && "s"}
          </Tag>
        }}
      >
        <EngagementSubMenu engagementId={engagementId} selectedResource={RESOURCE_PATH.CONTRACT} ></EngagementSubMenu>
        <Table {...tableProps}
          className="contracttable"
          sticky
          expandable={{
            expandRowByClick: true,
            expandedRowKeys: expandedRowKeys,
            onExpand: (expanded, rec) => {
              if (expanded) {
                setExpandedRowKeys([rec.id]);
              }
              else {
                setExpandedRowKeys([]);
              }
            },
            expandedRowRender: record => <ShowContract {...record} />
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedRowKeys: React.Key[]) => {
              setSelectedRowKeys(selectedRowKeys);
            },
            selections: [
              Table.SELECTION_ALL,
              Table.SELECTION_INVERT,
              Table.SELECTION_NONE,
            ],
          }}
          style={{ marginTop: 24 }}
          rowKey="id"
          pagination={{
            ...tableProps.pagination,
            position: ["bottomRight"],
            showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }}>Total {total}</Typography.Title>)
          }}
          onChange={(p, f, s, e) => {
            initialFilters.forEach(fl => {
              // Set the value to blank string if filter value is undefined.
              // This will avoid filter getting removed from URL and getting reset to equal operator.
              if (fl.operator !== "or" && !f[fl.field]) {
                f[fl.field] = [""];
              }
            });
            defaultOnChange(p, f, s, e);
          }}
        >
          <Table.Column
            dataIndex="name"
            key="name"
            title="Name"
            render={(value, record: IContract) => <>
              {value}
              <UrlField onClick={(e) => e.stopPropagation()} target={"_blank"} value={`https://dev.azure.com/tcscorpip/CorePipe/_workitems/edit/${record?.adoId}`}>
                <Icons.SelectOutlined title="View on ADO Core Pipe" rotate={90} style={{ marginLeft: 5 }} />
              </UrlField>
            </>}
            defaultSortOrder={getDefaultSortOrder("name", sorter)}
            sorter
            className="mouseHand"

            // Add text filter          
            filterDropdown={(props: FilterDropdownProps) => (
              <AppFilterDropdown {...props}>
                <Input />
              </AppFilterDropdown>
            )} />
          <Table.Column
            dataIndex="startDate"
            key="startDate"
            title="Start Date"
            render={(value) => value && <DateField format="ll" value={value} />}
            defaultSortOrder={getDefaultSortOrder("startDate", sorter)}
            sorter
            className="mouseHand" />

          <Table.Column
            dataIndex="endDate"
            key="endDate"
            title="End Date"
            render={(value) => value && <DateField format="ll" value={value} />}
            defaultSortOrder={getDefaultSortOrder("endDate", sorter)}
            sorter
            className="mouseHand" />
          <Table.Column
            dataIndex="businessGroup"
            key="businessGroup"
            title="Business Group"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("businessGroup", sorter)}
            sorter
            className="mouseHand"
            // Add text filter          
            filterDropdown={(props: FilterDropdownProps) => (
              <AppFilterDropdown {...props}>
                <Input />
              </AppFilterDropdown>
            )} />

          <Table.Column
            dataIndex="company"
            key="company"
            title="Company"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("company", sorter)}
            sorter
            className="mouseHand"
            // Add text filter          
            filterDropdown={(props: FilterDropdownProps) => (
              <AppFilterDropdown {...props}>
                <Input />
              </AppFilterDropdown>
            )} />

          <Table.Column
            key="brm"
            dataIndex="brm"
            title="BRM"
            className="mouseHand"
            defaultSortOrder={getDefaultSortOrder("brm", sorter)}
            sorter
            render={(value: string) => <ShowUserCard id={value} />}
            // Add text filter
            filterDropdown={(props) => (
              <AppFilterDropdown {...props}>
                <Input />
              </AppFilterDropdown>
            )} />

          <Table.Column
            dataIndex="state"
            key="state"
            title="State"
            render={(value: string) => <TextField value={value} />}
            sorter
            defaultSortOrder={getDefaultSortOrder("state", sorter)}

            // Add text filter                
            filterDropdown={(props: FilterDropdownProps) => (
              <AppFilterDropdown {...props}>
                <Input />
              </AppFilterDropdown>
            )} />
          <Table.Column
            dataIndex="updatedAt"
            key="updatedAt"
            title="Last Updated"
            render={displayRelativeDate}
            defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
            sorter
            className="mouseHand" />
        </Table>
      </List>
    </div>
  );
};
