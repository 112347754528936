import { AntdLayout, Button, Icons, Space, Typography } from "@pankod/refine-antd";
import { AAD_LOGIN, HELIUM_LOGO_PATH } from "scripts/site";

export const Login: React.FC = () => {
    return (
        <AntdLayout>
            <div style={{ height: "100vh", display: "flex" }}>
                <div style={{
                    margin: "auto"
                }}>
                    <Space direction="vertical" wrap align="center" >
                        <div style={{ marginBottom: "16px" }}>
                            <img src={HELIUM_LOGO_PATH} alt={process.env.REACT_APP_ApplicationTitle} style={{ maxWidth: "200px" }} />
                        </div>

                        <Typography.Title level={4} style={{ marginBottom: "0px" }}>
                            {`Welcome to ${process.env.REACT_APP_ApplicationTitle}`}
                        </Typography.Title>

                        <div style={{ marginBottom: "16px" }}>
                            <Typography.Text  >
                                An <span style={{ color: "limegreen" }}>
                                    A
                                </span>rchitecture, <span style={{ color: "limegreen" }}>
                                    I
                                </span>nnovation and <span style={{ color: "limegreen" }}>
                                    T
                                </span>ransformation team initiative
                            </Typography.Text>
                        </div>

                        <div style={{ width: 150 }}>
                            <Button
                                block
                                type="primary"
                                size="large"
                                href={AAD_LOGIN}
                                className="signin-button">
                                Sign in
                                <Icons.LoginOutlined />
                            </Button>
                        </div>
                        <div style={{ marginBottom: "22px" }}>
                            {
                                process.env.REACT_APP_BuildId &&
                                <Typography.Text style={{ color: "GrayText" }}>Version: {process.env.REACT_APP_BuildId}</Typography.Text>
                            }
                        </div>
                        <div>
                            <img src="/tcs-logo-2.png" alt="TCS" style={{ maxWidth: "50px" }} />
                        </div>
                    </Space>
                </div>
            </div>
        </AntdLayout>
    );
};