import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, ButtonProps, Popover, Tag, Typography } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { IUser } from "interfaces";
import { UserType } from "interfaces/enum";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";
import { ShowUserBasics } from "./showUserBasics";

export declare type UserCardProps = ButtonProps & {
    id: string | undefined;
};

export const ShowUserCard: React.FC<UserCardProps> = ({
    id,
    ...rest
}) => {
    const { data } = useOne<IUser>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PROFILE,
        id: id ?? "",
        queryOptions: {
            enabled: (id !== undefined && id !== ""),
            staleTime: 600000
        },
    });
    const userObj = data?.data;
    return (
        userObj
            ? <Button
                type="link"
                size="small"
                style={{ padding: 0 }}
                {...rest}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Popover
                    content={<ShowUserBasics {...userObj} />}
                    title={
                        <>
                            {userObj.name}
                            <Tag style={{ marginLeft: 5 }}
                                color={userObj.userType === UserType.Client ? "red" : "blue"}>
                                {stringExtensions.capitalize(userObj.userType)}
                            </Tag>
                        </>}>
                    {userObj.name ?? id}
                </Popover >
            </Button >
            : <Typography.Text copyable={!!id}>{id}</Typography.Text>
    );
};


