import {
  Button, Drawer, FilterDropdownProps, getDefaultSortOrder, Icons, Input, List, Table, Tag,
  TextField, Typography, useTable
} from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useNavigation, useOne } from "@pankod/refine-core";
import { AppFilterDropdown } from "components/common/AppFilterDropdown";
import { EvaluationModelFilter } from "components/evaluationmodel/evaluationmodelFilter";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { IEvaluationModel, IEvaluationModelMetadata } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_DEFAULT, DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const EvaluationModelList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const initialFilters: CrudFilters = [{
    field: "name",
    operator: "contains",
    value: ""
  }];

  const { data: entityMetadata } = useOne<IEvaluationModelMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.EVALUATIONMODEL
  });

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IEvaluationModel, HttpError, { q: string }>({
    dataProviderName: DATAPROVIDER_DEFAULT,
    resource: RESOURCE_PATH.EVALUATIONMODEL,
    initialSorter: [
      {
        field: "name",
        order: "asc"
      },
    ],
    initialFilter: initialFilters,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      return filters;
    },
  });

  const defaultOnChange = tableProps?.onChange ?? ((p, f, s, e) => { });
  const [filterOpen, switchFilterForm] = useState(false);
  const metaConfig = entityMetadata?.data?.config;

  function rowClick(record: IEvaluationModel) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.EVALUATIONMODEL, record.id);
      },
    };
  }

  return (
    <List
      title={<>
        {`Evaluation Models `}
        <Tag color={"default"}>
          {tableQueryResult.isLoading ? "Loading" : tableQueryResult?.data?.total} Model{tableQueryResult?.data?.total !== 1 && "s"}
        </Tag>
      </>}
      headerButtons={() => (
        <>
          <Button type="ghost" key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metaConfig?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
        </>
      )}
    >
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
        }}
        onChange={(p, f, s, e) => {
          initialFilters.forEach(fl => {
            // Set the value to blank string if filter value is undefined.
            // This will avoid filter getting removed from URL and getting reset to equal operator.
            if (fl.operator !== "or" && !f[fl.field]) {
              f[fl.field] = [""];
            }
          });
          defaultOnChange(p, f, s, e);
        }}
      >
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"

          // Add text filter          
          filterDropdown={(props: FilterDropdownProps) => (
            <AppFilterDropdown {...props} >
              <Input />
            </AppFilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="version"
          key="version"
          title="Version"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("version", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />
        <Table.Column
          dataIndex="active"
          key="active"
          title="Model state"
          render={(value) => value ? "Active" : "In-active"}
          defaultSortOrder={getDefaultSortOrder("active", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />
        <Table.Column
          dataIndex="modelType"
          key="modelType"
          title="Model Type"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("modelType", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />
        <Table.Column
          key="createdBy"
          dataIndex="createdBy"
          title="Created By"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string) =>
            <ShowUserCard id={value} />
          }
        />
        <Table.Column
          dataIndex="_ts"
          key="_ts"
          title="Last Updated"
          render={(value) => displayRelativeDate(new Date(value * 1000))}
          defaultSortOrder={getDefaultSortOrder("_ts", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />
      </Table>
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Engagement - Search & Filter"}
        placement="right"
        size="default"
        visible={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <EvaluationModelFilter formProps={searchFormProps} />
      </Drawer>
    </List>
  );
};
