import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import {
  Show, Table, TextField, Typography, useTable, getDefaultSortOrder, Button, Icons, RefreshButton, Tag, Modal
} from "@pankod/refine-antd";
import { BaseRecord, useNavigation } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import React from "react";
import { DATAPROVIDER_DEFAULT, RESOURCE_PATH } from "scripts/site";
const ReactJsonViewCompare = require("react-json-view-compare");

export const Notifications: React.FC = () => {

  const { tableProps, sorter } = useTable<BaseRecord>({
    dataProviderName: DATAPROVIDER_DEFAULT,
    resource: RESOURCE_PATH.NOTIFICATIONS
  });
  const { show } = useNavigation();
  const { info } = Modal;

  const showConfirm = (oldJson:any, newJson: any) => {

    const oldData = oldJson != null ? JSON.parse(oldJson) : {};
    const updatedData = newJson != null ? JSON.parse(newJson) : {};
        info({
          title: "Difference Viewer",
          icon: <Icons.DiffOutlined />,
          content: <ReactJsonViewCompare oldData={oldData} newData={updatedData} />,
          closable: true,
          maskClosable: true,
          width: "600",
          okText: "Close",
          onOk() {
            Modal.destroyAll();
          },
          onCancel() {
            Modal.destroyAll();
          },
        });
  };

  function rowClick(record: any) {
    return {
      onClick: () => {
        show(record?.operatedEntity, record?.operatedEntityId);
      },
    };
  }

  return (
    <Show
      title="Notifications"
      canDelete={false}
      pageHeaderProps={{
        backIcon: false,
        tags: <Tag color={"green"}>FYI Notifications</Tag>,
        extra: <><RefreshButton></RefreshButton></>
      }} >
      <Table {...tableProps}
        rowKey="rowKey"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total} notification(s)</Typography.Title>)
        }}
      >

        <Table.Column
          dataIndex="title"
          key="title"
          title="Title"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="operationPerformed"
          key="operationPerformed"
          title="Operation"
          render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
          defaultSortOrder={getDefaultSortOrder("operationPerformed", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="operationPerformedBy"
          key="operationPerformedBy"
          title="Updated By"
          render={(value: string) =>
            <ShowUserCard id={value} />
          }
          defaultSortOrder={getDefaultSortOrder("operationPerformedBy", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="operationPerformedAt"
          key="operationPerformedAt"
          title="Last Updated"
          render={displayRelativeDate}
          defaultSortOrder={getDefaultSortOrder("operationPerformedAt", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          title="Actions"
          key="actions"
          fixed="right"
          className="wrklstbtns"
          render={(v, record: any) => (
            <>
              <Button
                type="ghost"
                style={{ marginRight: 2 }}
                onClick={() => {
                  showConfirm(record.oldJson, record.updatedJson)
                }}
              >
                <Icons.DiffFilled />
                Changes
              </Button>
              <Button
                type="ghost"
                onClick={() => {
                  show(record.operatedEntity, record.operatedEntityId);
                }}
              >
                <Icons.EyeFilled />
                View
              </Button>
            </>
          )}
        />
      </Table>
    </Show>
  );
};
