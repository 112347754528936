import { Button, CreateButton, DateField, Drawer, FilterDropdownProps, getDefaultSortOrder, Icons, Input, List, Select, ShowButton, Table, TextField, Typography, useSelect, useTable } from "@pankod/refine-antd";
import { CanAccess, CrudFilters, HttpError, IResourceComponentsProps, useNavigation, useOne } from "@pankod/refine-core";
import { AppFilterDropdown } from "components/common/AppFilterDropdown";
import { EngagementFilter } from "components/engagement/engagementFilter";
import { EngagementSubMenu } from "components/engagement/subMenu";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { IEngagement, IEngagementMetadata, ILookup } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH } from "scripts/site";

export const EngagementList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();

  const initialFilters: CrudFilters = [{
    field: "name",
    operator: "contains",
    value: ""
  },
  {
    field: "businessGroup",
    operator: "contains",
    value: ""
  },
  {
    field: "salesExecutives",
    operator: "contains",
    value: ""
  },
  {
    field: "deliveryExecutives",
    operator: "contains",
    value: ""
  }];

  const { data: entityMetadata } = useOne<IEngagementMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ENGAGEMENTS
  });

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IEngagement, HttpError, {q: string}>({
    dataProviderName: DATAPROVIDER_READ,
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc"
      },
    ],
    permanentFilter: [{
      field: "_arrayfields",
      operator: "eq",
      value: "salesExecutives"
    },
    {
      field: "_arrayfields",
      operator: "eq",
      value: "deliveryExecutives"
    }],
    initialFilter: initialFilters,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      return filters;
    },
  });
  const defaultOnChange = tableProps?.onChange ?? ((p, f, s, e) => { });
  const [filterOpen, switchFilterForm] = useState(false);
  const metaConfig = entityMetadata?.data?.config;

  const { selectProps: userProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PROFILE,
    optionLabel: "name",
    optionValue: "id",
    sort: [
      {
        field: "lastAccessedAt",
        order: "desc"
      },
    ]
  });

  function rowClick(record: IEngagement) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.ENGAGEMENTS, record.id);
      },
    };
  }

  return (
    <List
      title={<>{(tableQueryResult?.data?.total ?? 0) + " Engagements"}</>}
      headerButtons={() => (
        <>
          <CanAccess key={RESOURCE_PATH.ENGAGEMENTS} resource={RESOURCE_PATH.ENGAGEMENTS.toLowerCase()} action="edit">
            <CreateButton></CreateButton>
          </CanAccess>
          <Button type="ghost" key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metaConfig?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
        </>
      )}
    >
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }}>Total {total}</Typography.Title>)
        }}
        onChange={(p, f, s, e) => {
          initialFilters.forEach(fl => {
            // Set the value to blank string if filter value is undefined.
            // This will avoid filter getting removed from URL and getting reset to equal operator.
            if (fl.operator !== "or" && !f[fl.field]) {
              f[fl.field] = [""];
            }
          });
          defaultOnChange(p, f, s, e);
        }}
      >
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"

          // Add text filter          
          filterDropdown={(props: FilterDropdownProps) => (
            <AppFilterDropdown {...props} >
              <Input />
            </AppFilterDropdown>
          )}
        />

        <Table.Column
          dataIndex="startDate"
          key="startDate"
          title="Start Date"
          render={(value) => value && <DateField format="ll" value={value} />}
          defaultSortOrder={getDefaultSortOrder("startDate", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="endDate"
          key="endDate"
          title="End Date"
          render={(value) => value && <DateField format="ll" value={value} />}
          defaultSortOrder={getDefaultSortOrder("endDate", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="businessGroup"
          key="businessGroup"
          title="Business Group"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("businessGroup", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"

          // Add text filter          
          filterDropdown={(props: FilterDropdownProps) => (
            <AppFilterDropdown {...props} >
              <Input />
            </AppFilterDropdown>
          )}
        />

        <Table.Column
          key="salesExecutives"
          dataIndex="salesExecutives"
          title="Sales Executives"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string[]) =>
            value && value.map((r, i) => (
              <div key={i} style={{ marginBottom: 5 }}>
                <ShowUserCard id={r} />
              </div>
            ))
          }

          // Add text filter
          filterDropdown={(props) => (
            <AppFilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select Users"
                {...userProps}
              />
            </AppFilterDropdown>
          )}
        />
        <Table.Column
          key="deliveryExecutives"
          dataIndex="deliveryExecutives"
          title="Delivery Executives"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string[]) =>
            value && value.map((r, i) => (
              <div key={i} style={{ marginBottom: 5 }}>
                <ShowUserCard id={r} />
              </div>
            ))
          }

          // Add text filter
          filterDropdown={(props) => (
            <AppFilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select Users"
                {...userProps}
              />
            </AppFilterDropdown>
          )}
        />

        <Table.Column
          dataIndex="updatedAt"
          key="updatedAt"
          title="Last Updated"
          render={displayRelativeDate}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />


        <Table.Column<IEngagement>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <>
              {/* Hidden button to let AuthZ module check access for it */}
              <ShowButton hidden size="small" recordItemId={record.id} />
              <EngagementSubMenu engagementId={record.id} renderAsMenu={false}></EngagementSubMenu>
            </>
          )}
        />
      </Table>
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Engagement - Search & Filter"}
        placement="right"
        size="default"
        visible={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <EngagementFilter formProps={searchFormProps} />
      </Drawer>
    </List>
  );
};