import { Alert, Col, Divider, EditButton, RefreshButton, Row, Show, Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useOne, useShow } from "@pankod/refine-core";
import { RenderHistoryModal } from "components/engagement/historyModal";
import { StateButtons } from "components/common/stateButtons";
import { ShowUserCard } from "components/profile/showUserCard";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IAsset, IAssetMetadata, IBaseProps, IUser } from "interfaces";
import { DATAPROVIDER_DEFAULT, DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";
const { Title, Text } = Typography;

export const AssetShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IAsset>({
    dataProviderName: DATAPROVIDER_DEFAULT
  });
  const { data, isLoading } = queryResult;
  const record = data?.data as IAsset;
 
  const { data: metadata } = useOne<IAssetMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ASSET,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });
  const { data: userData } = useGetIdentity();
  const userObj = userData as IUser;
  const assetTypeObj = metadata?.data.config.assetTypes.find(a => a.name === record?.name);
  const canAssign = (metadata?.data.config.roleIdsCanAssign ?? []).some(r => userObj?.entityRoleIds.some(ri => r === ri));

  return (
    <Show isLoading={isLoading}
      pageHeaderProps={{
        extra: <>
          {
          record ?
          <StateButtons {...record as IBaseProps}
            resourceToAct={RESOURCE_PATH.ASSET}
            resourceToActId={record?.id || "0"}
            navigateToId={""}
            resourceToNavigate={RESOURCE_PATH.PROFILE}
            hideActions={['submit', 'approve']}
            fetchAssignedToList={false}
            skipAssignedToCheck={canAssign}
          /> :
          ""
          }
          <EditButton></EditButton>
          <RefreshButton></RefreshButton>
          {record ? RenderHistoryModal(record.stateManager) : ""}
        </>
      }}
    >
      <Divider></Divider>
      <Row>
        <Col flex="auto">
          <Title level={5}>Allocated to</Title>
          {
            (record?.stateManager.assignedTo && record?.stateManager.assignedTo.length > 0) ?
              <ShowUserCard id={record?.stateManager.assignedTo[0]}></ShowUserCard>
              :
              <Text>Unallocated - Available for allocation</Text>
          }
          <Title style={{ marginTop: 15 }} level={5}>Asset ID</Title>
          <Text>{record?.id}</Text>

          <Title level={5}>Asset Type</Title>
          <Text>{record?.name}</Text>

          <Title level={5}>Serial Number</Title>
          <Text>{record?.serialNumber}</Text>

          <Title level={5}>Asset Type</Title>
          <Text>{record?.name}</Text>

          <Title level={5}>Asset Location</Title>
          <Text>{record?.location}</Text>
        </Col>
        <Col flex="auto">
          {
            (assetTypeObj?.makeModel ?? []).length > 0 ?
              <>
                <Title level={5}>Make/Model</Title>
                <Text>{record?.makeModel}</Text>
              </>
              : <></>}
          {
            (assetTypeObj?.processor ?? []).length > 0 ?
              <>
                <Title level={5}>Processor</Title>
                <Text>{record?.processor}</Text>
              </>
              : <></>
          }

          {
            (assetTypeObj?.ram ?? []).length > 0 ?
              <>
                <Title level={5}>RAM</Title>
                <Text>{record?.ram}</Text>
              </>
              : <></>
          }

          {
            (assetTypeObj?.storage ?? []).length > 0 ?
              <>
                <Title level={5}>Storage</Title>
                <Text>{record?.storage}</Text>
              </>
              : <></>
          }

          {
            (assetTypeObj?.resolution ?? []).length > 0 ?
              <>
                <Title level={5}>Resolution</Title>
                <Text>{record?.resolution}</Text>
              </>
              : <></>
          }

          {
            assetTypeObj?.macId === "" ?
              <>
                <Title level={5}>MAC ID</Title>
                <Text>{record?.macId}</Text>
              </>
              : <></>}
          {
            (assetTypeObj?.osImage ?? []).length > 0 ?
              <>
                <Title level={5}>OS Image Type</Title>
                <Text>{record?.osImage}</Text>
              </>
              : <></>}
          {
            assetTypeObj?.crNumber === "" && record.crNumber ?
              <>
                <Title level={5}>CR Number</Title>
                <Text>{record?.crNumber}</Text>
              </>
              : <></>}
        </Col>
      </Row>
      <Divider></Divider>
      {
        record?.stateManager.workflowTitle ?
          <>
            <Alert
              message="Informational Note"
              description={record?.stateManager.workflowTitle}
              type="info"
              showIcon
            />
          </>
          : <></>
      }
      <Divider></Divider>
      <div style={{ marginTop: 15 }}>
        <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
      </div>
    </Show>
  );
};
