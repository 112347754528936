import { Button, Icons, Modal } from "@pankod/refine-antd";
import { MermaidView } from "components/common/mermaidView";
import { IStateManager } from "interfaces";
import { stateManagerToMermaid } from "scripts/site";

export const RenderHistoryModal: React.FC<IStateManager> = (stateManager) => {
    function WorkflowModal(dataContent: any) {
        const modal = Modal.info({
            title: 'Workflow ',
            content: dataContent,
            width: '50%',
            className: 'hstrymdl',
            maskClosable: true,
            closable: true,
            onOk() { modal.destroy() }
        });
    }
    return (
        <Button
            className="hst"
            title="View Workflow"
            type="ghost"
            onClick={() => {
                WorkflowModal(
                    <MermaidView placeholderId="hstrymrmadmdl" graphDefinition={stateManagerToMermaid(stateManager)}></MermaidView>
                );
            }}
        >
            <Icons.HistoryOutlined />
            Workflow
        </Button>
    );
};