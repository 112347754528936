import { AntdLayout, Badge, Button, Col, Grid, Icons, ListButton, Row, Space, Spin, Switch, Tooltip, Typography } from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { IUser } from "interfaces";
import mermaid from "mermaid";
import mermaidAPI from "mermaid/mermaidAPI";
import { useState } from "react";
import { AAD_LOGOUT, HELIUM_LOGO_PATH, RESOURCE_PATH, clearLocalSessionStorage, useNotifications } from "scripts/site";
import { applyTheme } from "styles/themes";

const { Text } = Typography;
const { useBreakpoint } = Grid;

export const AppHeader: React.FC = () => {
    const { data, isLoading } = useGetIdentity();
    const user = data as IUser;
    const screens = useBreakpoint();
    const { tableQueryResult } = useNotifications();

    const [currentTheme, setTheme] = useState("dark");
    applyTheme(currentTheme);

    const onColorChange = () => {
        const nextTheme = currentTheme === "light" ? "dark" : "light";
        setTheme(nextTheme);
        applyTheme(nextTheme);
        mermaid.initialize({ startOnLoad: false, theme: nextTheme as mermaidAPI.Theme, securityLevel: "loose" as mermaidAPI.SecurityLevel })
    };

    return (
        <AntdLayout.Header
            style={{
                padding: "0px 24px",
            }}
        >
            <Row align="middle" justify={screens.sm ? "space-between" : "space-between"}>
                <Col xs={2} sm={2} lg={0}>

                    <Tooltip title="Reload">
                        <a href="/" onClick={() => clearLocalSessionStorage(false)}>
                            <img
                                src={HELIUM_LOGO_PATH}
                                alt="TCS"
                                className="app-logo-header"
                            />
                        </a>
                    </Tooltip>
                </Col>
                <Col xs={4} sm={4}>
                </Col>
                <Col>
                    <Spin spinning={isLoading}>
                        <Space size="small" wrap>
                            <Switch checkedChildren="Light" unCheckedChildren="Dark" defaultChecked onChange={() => { onColorChange(); }} />
                            <Badge count={isLoading ? 0 : tableQueryResult?.data?.total} showZero={false} dot offset={[-10, 5]}>
                                <ListButton className="usrprflnk" type="link" icon={false} resourceName={RESOURCE_PATH.WORKLIST} >
                                    <Tooltip title={
                                        <>
                                            <Text strong>{user?.id}</Text>
                                            {
                                                user?.linkedUserIds?.map((r, i) => <div key={i} ><Text italic>{r}</Text></div>)
                                            }
                                        </>
                                    }>
                                        {user?.name}
                                    </Tooltip>
                                </ListButton>
                            </Badge>
                            <Button title="Logout" danger style={{ padding: 5 }} href={AAD_LOGOUT} type="link" icon={<Icons.PoweroffOutlined />} />
                        </Space>
                    </Spin>
                </Col>
            </Row>
        </AntdLayout.Header>
    );
};
