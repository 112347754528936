import { Button, Divider, Drawer, Icons, Show, Tabs } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCan, useOne } from "@pankod/refine-core";
import { OutreachForm, RenderMDFile, RenderPDFFile } from "components/common";
import { IMetadata } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const ABMDeskList: React.FC<IResourceComponentsProps> = () => {

    const { data: metadata, isLoading } = useOne<IMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.INFO_ABMDESK,
        queryOptions: {
            enabled: true,
            staleTime: 300000
        }
    });
    const metaFields = metadata?.data.fields;
    const [outreachOpen, switchOutreach] = useState(false);
    const canListExpertOutreach = useCan({
        resource: RESOURCE_PATH.EXPERT_OUTREACH,
        action: "create"
    });

    return (
        <div className="abmdesk">
            <Show
                isLoading={isLoading}
                title="ABM Desk"
                headerProps={{
                    backIcon: false,
                    extra: canListExpertOutreach.data?.can &&
                        <Button type="ghost" key="help" onClick={() => switchOutreach(true)} icon={<Icons.ReadOutlined />}>Connect with ABM Desk</Button>

                }}
            >
                <Divider style={{ marginTop: 6, borderWidth: 2 }}></Divider>
                <Tabs tabPosition="left">
                    {metaFields && metaFields.map((m, i) => (
                        <Tabs.TabPane key={i} tab={m.name}>
                            {m.format === "pdf" ?
                                <RenderPDFFile resourceName={RESOURCE_PATH.INFO_ABMDESK} pdfFilePath={m.attachment} />
                                :
                                <RenderMDFile resourceName={RESOURCE_PATH.INFO_ABMDESK} mdFilePath={m.attachment} />
                            }
                        </Tabs.TabPane>
                    ))}
                </Tabs>

                {canListExpertOutreach.data?.can &&
                    <Drawer
                        title="Connect with ABM Desk"
                        placement="right"
                        size="large"
                        visible={outreachOpen}
                        onClose={() => { switchOutreach(false) }}
                    >
                        <OutreachForm categoryType="abmdesk" />
                    </Drawer>
                }
            </Show>
        </div>
    );
};
