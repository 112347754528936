import React from "react";
import {
    BrowserRouter,
    BrowserRouterProps, HashRouter, HashRouterProps, MemoryRouter,
    MemoryRouterProps
} from "react-router-dom";
import { RouteProvider } from "./routeProvider";


export const BrowserRouterComponent: React.FC<BrowserRouterProps> = ({
    children,
    ...props
}) => {
    return (
        <BrowserRouter {...props}>
            <RouteProvider />
            {children}
        </BrowserRouter>
    );
};

export const HashRouterComponent: React.FC<HashRouterProps> = ({
    children,
    ...props
}) => {
    return (
        <HashRouter {...props}>
            <RouteProvider />
            {children}
        </HashRouter>
    );
};

export const MemoryRouterComponent: React.FC<MemoryRouterProps> = ({
    children,
    ...props
}) => {
    return (
        <MemoryRouter {...props}>
            <RouteProvider />
            {children}
        </MemoryRouter>
    );
};
