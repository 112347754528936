import { Tabs } from "@pankod/refine-antd";
import { IStateManager } from "interfaces";
import { HistoryTable } from "./historyTable";
import { TimelineView } from "./timelineView";

type TabPosition = 'left' | 'right' | 'top' | 'bottom';
interface IHistoryTab {
    stateManager: IStateManager,
    tabPosition: TabPosition
}

export const HistoryTab: React.FC<IHistoryTab> = ({stateManager, tabPosition = 'top'}) => {
    
    return (
        <>
            <Tabs
                tabPosition={tabPosition}
            >
                <Tabs.TabPane
                    tab="Timeline View"
                    key="timeline"
                    forceRender
                    style={{ overflowY: "auto", overflowX: 'hidden', height: '488px' }}
                >
                    <TimelineView {...stateManager} />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Table View"
                    key="table"
                    forceRender
                    style={{ height: '488px' }}
                >
                    <HistoryTable {...stateManager}/>
                </Tabs.TabPane>
            </Tabs>
        </>
    );
};