import { DatePicker, Edit, Form, Input, SaveButton, Select, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import dayjs, { Dayjs } from "dayjs";
import { IEngagement, ILookup } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_DEFAULT, DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const EngagementEdit: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, queryResult } = useForm<IEngagement>({
        dataProviderName: DATAPROVIDER_DEFAULT
    });

    const { selectProps: deliveryProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PROFILE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "deliveryExecutive"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }],
    });

    const { selectProps: salesProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PROFILE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "salesExecutive"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }],
    });

    const { selectProps: otherUserProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PROFILE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "engagementAuthorizedUser"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }],
    });

    const { selectProps: clients } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PROFILE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "client"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }],
    });

    const currentStartDate = dayjs(queryResult?.data?.data?.startDate);
    if (queryResult?.data?.data?.startDate) {
        queryResult.data.data.startDate = dayjs(queryResult?.data?.data?.startDate);
    }

    if (queryResult?.data?.data?.endDate) {
        queryResult.data.data.endDate = dayjs(queryResult?.data?.data?.endDate);
    }

    const [startDate, setStartDate] = useState<Dayjs>(currentStartDate);

    return (
        <Edit //saveButtonProps={saveButtonProps}
            // actionButtons={
            //     <>
            //         <SaveButton {...saveButtonProps} />
            //         <CancelButton />
            //     </>
            // }
            footerButtons={() => (
                <>
                    <SaveButton {...saveButtonProps} />
                    <CancelButton />
                </>
            )}
        >
            <Form {...formProps} autoComplete="off" layout="vertical">
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            min: 5,
                            max: 100
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Business Group"
                    name="businessGroup"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            max: 100
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Start Date"
                    name="startDate"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            type: "date"
                        }
                    ]}
                >
                    <DatePicker picker="date"
                        style={{ width: 280 }}
                        placeholder="YYYY-MM-DD"
                        onChange={(val) => val && setStartDate(val)}
                    />
                </Form.Item>


                <Form.Item
                    label="End Date"
                    name="endDate"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            type: "date"
                        }
                    ]}
                >
                    <DatePicker picker="date"
                        style={{ width: 280 }}
                        placeholder="YYYY-MM-DD"
                        disabledDate={(selDate) => selDate && !!startDate && selDate < startDate}
                    />
                </Form.Item>

                <Form.Item
                    label="Sales Executives"
                    name="salesExecutives"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            type: "array"
                        },
                    ]}
                >
                    <Select placeholder="Search Name or Email" mode="multiple" {...salesProps} allowClear />
                </Form.Item>

                <Form.Item
                    label="Delivery Executives"
                    name="deliveryExecutives"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            type: "array",
                        },
                    ]}
                >
                    <Select placeholder="Search Name or Email" mode="multiple"  {...deliveryProps} allowClear />
                </Form.Item>

                <Form.Item
                    label="Other Authorized users"
                    name="otherAuthorizedUsers"
                    rules={[
                        {
                            required: false,
                            whitespace: true,
                            type: "array",
                        },
                    ]}
                >
                    <Select placeholder="Search Name or Email" mode="multiple"  {...otherUserProps} allowClear />
                </Form.Item>

                <Form.Item
                    label="Clients"
                    name="clients"
                    rules={[
                        {
                            required: false,
                            whitespace: true,
                            type: "array"
                        },
                    ]}
                >
                    <Select placeholder="Search Name or Email" mode="multiple" {...clients} allowClear />
                </Form.Item>

            </Form>
        </Edit >
    );
};