import { AntdList, Button, Card, DeleteButton, Icons, List, Steps, Tag, Timeline, Tooltip, Typography, useDrawerForm, useSimpleList } from "@pankod/refine-antd";
import { CanAccess, CrudFilter, IResourceComponentsProps, useCan, useCreate, useList, useOne } from "@pankod/refine-core";
import { CreateAssessment } from "components/engagement/createAssessment";
import { EngagementSubMenu } from "components/engagement/subMenu";
import { NavigationButtons } from "components/evaluation/navigationButtons";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { DisplayScore } from "components/utils/displayScore";
import { RenderLink } from "components/utils/renderLink";
import { IAssessment, IBaseProps, IEvaluation, IEvaluationModel, ILookup, IMetadata } from "interfaces";
import { useLocation } from "react-router-dom";
import { DATAPROVIDER_DEFAULT, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, getQueryStringParams, RESOURCE_PATH } from "scripts/site";

export const AssessmentList: React.FC<IResourceComponentsProps> = () => {
    const { search } = useLocation();
    const { engagementId } = getQueryStringParams(search);

    const assignedFilter: CrudFilter[] = engagementId ? [{
        field: "engagementId",
        operator: "eq",
        value: engagementId
    }] : [];

    const { queryResult: dataList, listProps } = useSimpleList<IAssessment>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.ASSESSMENT,
        permanentFilter: assignedFilter,
        permanentSorter: [{
            field: "updatedAt",
            order: "desc"
        }],
        pagination: {
            pageSize: 3
        },
        syncWithLocation: false
    });

    const assessment_ids = dataList?.data?.data?.map(item => item?.id) ?? [];

    const { data: evalList } = useList<IEvaluation>({
        dataProviderName: DATAPROVIDER_DEFAULT,
        resource: RESOURCE_PATH.EVALUATION,
        config: {
            filters: [{
                field: "assessmentId",
                operator: "in",
                value: assessment_ids
            }],
            sort: [{
                field: "updatedAt",
                order: "desc"
            }]
        },
        queryOptions: {
            enabled: assessment_ids.length > 0,
        }
    });

    const {
        drawerProps,
        formProps,
        saveButtonProps,
        show: openAssessmentModal,
    } = useDrawerForm<IAssessment>({
        action: "create",
        resource: RESOURCE_PATH.ASSESSMENT,
        dataProviderName: DATAPROVIDER_DEFAULT,
        redirect: false,
    });

    const { mutate } = useCreate<IEvaluation>();

    const { data: evaluationMetadata } = useOne<IMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.EVALUATION
    });

    const { data: evaluationModelList } = useList<IEvaluationModel>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.EVALUATIONMODEL,
    });

    const { data: alreadyCreatedAssessment } = useList<IAssessment>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.ASSESSMENT,
        config: {
            filters: [{
                field: "engagementId",
                operator: "eq",
                value: engagementId
            }]
        },
    });
    //const greyStyle = { color: "GrayText" };
    const entityStates = evaluationMetadata?.data?.states;
    const evaluationModels = evaluationModelList?.data;
    const alreadyPresentModel = alreadyCreatedAssessment?.data?.map(item => item.evaluationModelId) ?? [];
    const remainingModels = evaluationModels?.filter(mod => mod.active && !alreadyPresentModel.includes(mod.id));

    const canDeleteAssessment = useCan({
        resource: RESOURCE_PATH.ASSESSMENT,
        action: "delete"
    });

    const renderItem = (item: IAssessment, index: number) => {
        const assessItems = evalList?.data.filter(ev => ev.assessmentId === item.id) || [];
        // const stateSteps: any = [];
        // entityStates?.forEach((st, indx) => {
        //     stateSteps.push(<Steps.Step key={`${item.id}-${indx}`} title={st.displayName} />);
        // });
        const actions = [];
        if (assessItems.length === 0 || (assessItems.length > 0 && !assessItems.find(x => x.stateManager.state !== 'approve'))) {
            actions.push(<Button
                icon={<Icons.PlusOutlined />}
                title="Start New Evaluation"
                onClick={(): void => {
                    mutate({
                        dataProviderName: DATAPROVIDER_DEFAULT,
                        resource: RESOURCE_PATH.EVALUATION,
                        values: {
                            assessmentId: item.id,
                            engagementId: item.engagementId,
                            evaluationModelId: item.evaluationModelId,
                        }
                    });
                }}>
                Evaluation
            </Button>);
        }
        if (canDeleteAssessment.data?.can && assessItems.length === 0) {
            actions.push(<DeleteButton resourceName={RESOURCE_PATH.ASSESSMENT} recordItemId={item.id} title="Delete Empty Assessment">Delete</DeleteButton>);
        }
        return (
            <AntdList.Item key={`asmt-${index}`}>
                <Card className="tmlncardttl" title={
                    <RenderLink id={item?.evaluationModelId || ""} name={evaluationModels?.find(md => md.id === item.evaluationModelId)?.name} resource={RESOURCE_PATH.EVALUATIONMODEL}></RenderLink>
                }
                    extra={<>
                        <Tag color="blue">{`V ${evaluationModels?.find(md => md.id === item.evaluationModelId)?.version ?? 0}`}</Tag>
                    </>}
                    actions={actions}>
                    {assessItems.length === 0
                        ? <Typography.Text italic style={{ color: "GrayText" }}>No evaluations yet</Typography.Text>
                        :
                        <Timeline mode="left" className="otrtmln">
                            {assessItems.map((ev, i) => {
                                const currentOrder = entityStates?.find(x => x.state === ev.stateManager?.workflow?.at(-1)?.state)?.order ?? 0;
                                return (<Timeline.Item key={`otrtmline-${index}-${i}`}>
                                    <Card type="inner" className={"inrcard"}
                                        title={displayRelativeDate(ev.updatedAt)}
                                        extra={
                                            <>
                                                <DisplayScore
                                                    ui={ev.evaluationModel.ui}
                                                    output={ev.evaluationModel.output}
                                                />
                                            </>}>
                                        <Steps key={`inrtmline-${index}-${i}`}
                                            className="inrtmln"
                                            direction="vertical"
                                            size="small"
                                        //progressDot
                                        //current={entityStates?.find(x => x.state === ev.stateManager.state)?.order}
                                        >
                                            {entityStates?.map((es, esIdx) => {
                                                const isCompleted = ev.stateManager?.workflow?.some(x => x.state === es.state)
                                                    && es.order <= currentOrder;
                                                return (<Steps.Step
                                                    title={es.displayName}
                                                    key={`evalflow-${esIdx}`}
                                                    status={isCompleted ? "finish" : "wait"}
                                                />);
                                            })}

                                            {/* {ev.stateManager?.workflow?.map((wf, wfIdx) => {
                                                const enState = entityStates?.find(x => x.state === wf.state);
                                                return (<Steps.Step
                                                    key={`evalflow-${wfIdx}`}
                                                    title={<>
                                                        {enState?.displayName ?? wf.state}
                                                        <Typography.Text style={greyStyle}> | </Typography.Text>
                                                        {displayRelativeDate(wf.endTS !== "" ? wf.endTS : wf.beginTS)}
                                                    </>}
                                                    status={(wfIdx < (ev.stateManager.workflow.length - 1) || wf.assignedTo.length === 0) ? "finish" : "wait"}
                                                    description={<ShowUserCard id={wf.stateChangedBy}></ShowUserCard>} />);
                                            })} */}
                                            {/* {stateSteps} */}
                                        </Steps>
                                    </Card>
                                    <NavigationButtons {...ev as IBaseProps}
                                        navigateToId={ev.id}
                                        resourceToNavigate={RESOURCE_PATH.EVALUATION}
                                    />
                                </Timeline.Item>);
                            })}
                        </Timeline>
                    }
                </Card>
            </AntdList.Item >
        );
    };

    const { data: enggName } = useOne<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.ENGAGEMENTS,
        id: engagementId,
        queryOptions: {
            enabled: !!engagementId
        },
    });
    const totalAssess = dataList?.data?.total ?? 0;
    return (
        <div className="">
            <List
                title={enggName?.data?.name}
                pageHeaderProps={{
                    tags: <Tag color={"default"}>
                        {dataList.isLoading ? "Loading" : totalAssess} Assessment{totalAssess !== 1 && "s"}
                    </Tag>,
                    //tags: <Tag>{(dataList?.data?.total ?? 0) + " Assessments"}</Tag>,
                    extra:
                        <CanAccess key={RESOURCE_PATH.ASSESSMENT} resource={RESOURCE_PATH.ENGAGEMENTS.toLowerCase()} action="create">
                            {
                                (remainingModels && remainingModels?.length > 0) ?
                                    <Button icon={<Icons.PlusOutlined />} onClick={() => openAssessmentModal()}>
                                        Assessment
                                    </Button>
                                    :
                                    <Tooltip title="No models available to initiate assessment" placement="bottom">
                                        <Button disabled icon={<Icons.PlusOutlined />}>Assessment</Button>
                                    </Tooltip>
                            }
                        </CanAccess>
                }}
            >
                <EngagementSubMenu engagementId={engagementId} selectedResource={RESOURCE_PATH.ASSESSMENT}></EngagementSubMenu>
                <div style={{ marginTop: 24 }}>
                    <AntdList itemLayout="vertical"
                        grid={{
                            gutter: 20,
                            xs: 1,
                            sm: 1,
                            md: 2,
                            lg: 2,
                            xl: 3,
                            xxl: 3,
                        }}
                        {...listProps}
                        loading={dataList.isLoading}
                        renderItem={renderItem}
                        pagination={{
                            ...listProps.pagination,
                            position: "bottom",
                            showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }}>Total {total}</Typography.Title>)
                        }}
                    >
                    </AntdList>
                </div>
            </List>
            <CreateAssessment
                drawerProps={drawerProps}
                engagementId={engagementId}
                formProps={formProps}
                saveButtonProps={saveButtonProps}
                evaluationModelList={remainingModels ?? []} />
        </div>
    );
};


