import { Icons, Tooltip, Typography } from "@pankod/refine-antd";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { IUser } from "interfaces";
import { parseRelativeTimeZone, parseTimeZone } from "scripts/site";

export declare type BasicProfileProps = IUser & {
    isCurrentUser?: boolean;
};
export const ShowUserBasics: React.FC<BasicProfileProps> = (userObj: BasicProfileProps) => {

    const timezoneInfo = parseRelativeTimeZone(userObj?.userTime);

    return (
        <>
            <div style={{ fontSize: 14, marginBottom: 10 }}>
                <Icons.MailOutlined style={{ marginRight: 10 }} />
                <Typography.Text copyable>{userObj?.id ?? "---"}</Typography.Text>
            </div>

            {userObj?.employee?.phoneNumber &&
                <div style={{ fontSize: 14, marginBottom: 10 }}>
                    <Icons.PhoneOutlined rotate={90} style={{ marginRight: 10 }} />
                    <Typography.Text copyable>{userObj?.employee?.phoneNumber ?? "---"}</Typography.Text>
                </div>
            }

            {userObj?.locationId &&
                <div style={{ fontSize: 14, marginBottom: 10 }}>
                    <Icons.EnvironmentOutlined style={{ marginRight: 10 }} />
                    <Typography.Text>{userObj?.locationId ?? "---"}</Typography.Text>
                </div>
            }

            {userObj?.userTime &&
                <div style={{ fontSize: 14, marginBottom: 10 }}>

                    <Icons.ClockCircleOutlined style={{ marginRight: 10 }} />
                    {userObj.isCurrentUser
                        ? <Typography.Text>{parseTimeZone(userObj.userTime)}</Typography.Text>
                        : <>
                            <Tooltip title={parseTimeZone(userObj.userTime)}>
                                <Typography.Text>{timezoneInfo.text}</Typography.Text>
                            </Tooltip>
                            {(timezoneInfo.relativeType === "Ahead" || timezoneInfo.relativeType === "Behind")
                                && <Typography.Text>{userObj.name.split(" ")[0]}</Typography.Text>}
                        </>
                    }
                </div>
            }

            <div style={{ fontSize: 14 }}>
                <Icons.EyeOutlined style={{ marginRight: 10 }} />
                <Typography.Text>{userObj?.lastAccessedAt ? <>Last seen {displayRelativeDate(userObj?.lastAccessedAt)}</> : "Never seen"}</Typography.Text>
            </div>
        </>
    );
};

