import { TextField } from "@pankod/refine-antd";
import dayjs from "dayjs";
import { ISLA, IStateManager } from "interfaces";
import { formatMS } from "scripts/site";

export function slaDeadline(
    sla: ISLA | undefined,
    stateManager: IStateManager | undefined
): JSX.Element {
    try {
        // init variables
        let result: string = "---";
        let msSpent: number = 0;
        let hrsSpent: number = 0;
        const msInHrs: number = 3600000;
        const maxHours: number = sla?.maxHours || 0;
        const maxMS: number = maxHours * msInHrs;
        const prec: number = 0;

        // iterate through states, adding up time spent in non-paused states
        stateManager?.workflow.forEach((item) => {
            // if case closed then re-opened, reset time spent
            if (item.state === "open") {
                msSpent = 0;
            }
            if (!sla?.pauseStates.find((e) => (e === item.state))) {
                let start: dayjs.Dayjs = dayjs(new Date(item.beginTS));
                let end: dayjs.Dayjs = (item.endTS.length > 0) ? dayjs(new Date(item.endTS)) : dayjs(Date.now());
                msSpent += end.diff(start);
            }
        })

        hrsSpent = msSpent/msInHrs;

        // is in end state
        if (sla?.endStates.find((e) => (e === stateManager?.state))) {
            result = formatMS(msSpent, false, false) + " spent";
            return (<TextField value={result} type="secondary" />);
        }
        // is not closed
        else {
            // has not passed deadline
            if (msSpent < maxMS) {
                // is paused
                if (sla?.pauseStates.find((e) => (e === stateManager?.state))) {
                    result = (maxHours-hrsSpent).toFixed(prec) + " / " + maxHours + " hours left (paused)";
                    return (<TextField value={result} type="secondary" />);
                }
                // not paused
                else {
                    // less than 1 hour left
                    if (maxMS - msSpent < msInHrs) {
                        result = formatMS(maxMS - msSpent, false, false, 3) + " left";
                        return (<TextField value={result} type="warning" />);
                    }
                    // more than 1 hour left
                    else {
                        result = (maxHours-hrsSpent).toFixed(prec) + " / " + maxHours + " hours left";
                        return (<TextField value={result} />);
                    }
                }
            }
            // has passed deadline
            else {
                // is paused
                if (sla?.pauseStates.find((e) => (e === stateManager?.state))) {
                    result = formatMS(msSpent - maxMS, false, false, 3) + " overdue (paused)";
                    return (<TextField value={result} type="secondary" />);
                }
                // not paused
                else {
                    result = formatMS(msSpent - maxMS, false, false, 3) + " overdue";
                    return (<TextField value={result} type="danger" />);
                }
            }
        }
    }
    catch {
        return (<TextField value="---" type="secondary" />);
    }
}