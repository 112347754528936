import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import {
  Button,
  CreateButton, Drawer, ExportButton, getDefaultSortOrder, Icons, List, Table, Tag, TextField,
  Typography, useTable
} from "@pankod/refine-antd";
import {
  CanAccess,
  CrudFilters, HttpError, IResourceComponentsProps, useExport, useNavigation,
  useOne
} from "@pankod/refine-core";
import { CaseFilter } from "components/case/caseFilter";
import { RenderWorkflowModal } from "components/common/workflowModal";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { LookupButton } from "components/utils/lookupButton";
import { slaDeadline } from "components/utils/slaDeadline";
import { ICase, ICaseFilterVariables, ICaseMetadata, IState, IUser } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH } from "scripts/site";
import { SelectTransform } from "selecttransform";

export const CaseList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const initialFilters: CrudFilters = [{
    field: "name",
    operator: "contains",
    value: ""
  },
  {
    field: "id",
    operator: "contains",
    value: ""
  }];

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<ICase, HttpError, ICaseFilterVariables>({
    dataProviderName: DATAPROVIDER_READ,
    initialSorter: [
      {
        field: "lastAccessedAt",
        order: "desc"
      },
    ],
    initialFilter: initialFilters,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, state, assignedTo, lineOfBusinessId, programId, caseTypeId, subCaseTypeId, caseReasonId, subReasonId } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "stateManager.state",
        operator: "eq",
        value: state
      });
      filters.push({
        field: "stateManager.assignedTo ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: assignedTo
      });
      filters.push({
        field: "lineOfBusinessId",
        operator: "eq",
        value: lineOfBusinessId
      });
      filters.push({
        field: "programId",
        operator: "eq",
        value: programId
      });
      filters.push({
        field: "caseTypeId",
        operator: "eq",
        value: caseTypeId
      });

      filters.push({
        field: "subCaseTypeId",
        operator: "eq",
        value: subCaseTypeId
      });
      filters.push({
        field: "caseReasonId",
        operator: "eq",
        value: caseReasonId
      });
      filters.push({
        field: "subReasonId",
        operator: "eq",
        value: subReasonId
      });
      return filters;
    }
  });

  const defaultOnChange = tableProps?.onChange ?? ((p, f, s, e) => { });

  function rowClick(record: IUser) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.CASE, record.id);
      },
    };
  }

  const { data: entityMetadata } = useOne<ICaseMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.CASE,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });
  const metaConfig = entityMetadata?.data?.config;
  const [filterOpen, switchFilterForm] = useState(false);
  const exportTransform = new SelectTransform(true);
  const entityStates = entityMetadata?.data?.states as IState[] ?? [];

  // Commenting import export functionality as its not ready to be released. 
  const { triggerExport, isLoading: exportLoading } = useExport<IUser>(
    {
      mapData: (item) => exportTransform.transformSync(metaConfig?.export?.transform, item, false),
      pageSize: metaConfig?.export?.pageSize ?? 100,
    });

  return (
    <List
      pageHeaderProps={{
        title: "Cases",
        tags: <Tag color={"default"}>
          {tableQueryResult.isLoading ? "Loading" : tableQueryResult?.data?.total} Case{tableQueryResult?.data?.total !== 1 && "s"}
        </Tag>,
        extra: <>
          <CanAccess resource={RESOURCE_PATH.CASE.toLowerCase()} action="create">
            <CreateButton></CreateButton>
          </CanAccess>
          {entityStates.length > 0 ? RenderWorkflowModal(entityStates) : <></>}
          {metaConfig?.export?.enabled && <ExportButton onClick={triggerExport} loading={exportLoading} />}
          <Button type="ghost" key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metaConfig?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
        </>
      }}
    >
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }}>Total {total}</Typography.Title>)
        }}
        onChange={(p, f, s, e) => {
          initialFilters.forEach(fl => {
            // Set the value to blank string if filter value is undefined.
            // This will avoid filter getting removed from URL and getting reset to equal operator.
            if (fl.operator !== "or" && !f[fl.field]) {
              f[fl.field] = [""];
            }
          });
          defaultOnChange(p, f, s, e);
        }}>

        <Table.Column
          dataIndex="name"
          key="name"
          title="Case Title"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="stateManager"
          key="slaDeadline"
          title="SLA Deadline"
          render={(value) => slaDeadline(metaConfig?.sla, value)}
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex={["stateManager", "state"]}
          key="stateManager.state"
          title="State"
          render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
          defaultSortOrder={getDefaultSortOrder("stateManager.state", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex={["stateManager", "assignedTo"]}
          key="stateManager.assignedTo"
          title="Assigned To"
          render={(value: string[]) => value.length !== 0 ? <ShowUserCard id={value[0]} /> : <></>}
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="lineOfBusinessId"
          key="lineOfBusinessId"
          title="Line Of Business"
          render={(value) => <LookupButton id={value || ""} resource={RESOURCE_PATH.LINE_OF_BUSINESS}></LookupButton>}
          onCell={rowClick}
          className="mouseHand"
        />
        <Table.Column
          dataIndex="programId"
          key="programId"
          title="Program"
          render={(value) => <LookupButton id={value || ""} resource={RESOURCE_PATH.PROGRAM}></LookupButton>}
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="subReasonId"
          key="subReasonId"
          title="Sub Reason"
          render={(value) => <LookupButton id={value || ""} resource={RESOURCE_PATH.SUB_REASON}></LookupButton>}
          onCell={rowClick}
          className="mouseHand"

        />

        <Table.Column
          dataIndex="countryId"
          key="country"
          title="Country"
          render={(value) => <LookupButton id={value || ""} resource={RESOURCE_PATH.COUNTRY}></LookupButton>}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="teamId"
          key="country"
          title="Team"
          render={(value) => <LookupButton id={value || ""} resource={RESOURCE_PATH.TEAM}></LookupButton>}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="categoryId"
          key="category"
          title="Category"
          render={(value) => <LookupButton id={value || ""} resource={RESOURCE_PATH.CATEGORY}></LookupButton>}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="updatedAt"
          key="updatedAt"
          title="Last Updated"
          render={displayRelativeDate}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

      </Table>
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Cases - Search & Filter"}
        placement="right"
        size="default"
        visible={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <CaseFilter formProps={searchFormProps} />
      </Drawer>
    </List>
  );
};
