import {
  Create, Divider, Form, Input, SaveButton, useStepsForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { CaseFieldsRadio } from "components/case/caseFieldsRadio";
import { CaseFieldsRegionRadio } from "components/case/caseFieldsRegionRadio";
import { CaseFieldsTeamRadio } from "components/case/caseFieldsTeamRadio";
import { CancelButton } from "components/utils/cancelButton";
import { ICase } from "interfaces";
import { DATAPROVIDER_CREATE } from "scripts/site";

export const CaseCreate: React.FC<IResourceComponentsProps> = () => {

  const { formProps, saveButtonProps, queryResult, form } = useStepsForm<ICase>({
    dataProviderName: DATAPROVIDER_CREATE,

  });

  return (
    <Create

      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
          <CancelButton />
        </>
      )}>
      <Form {...formProps} layout="vertical" autoComplete="off">
        <Form.Item
          label="Case Title"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              max: 100
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Divider dashed />
        <CaseFieldsRegionRadio isLoading={queryResult?.isLoading ?? true} form={form} />
        <br />
        <Divider dashed />
        <CaseFieldsRadio isLoading={queryResult?.isLoading ?? true} form={form} />
        <br />
        <Divider dashed />
        <CaseFieldsTeamRadio isLoading={queryResult?.isLoading ?? true} form={form} />

        <Form.Item
          label="Notes"
          name="notes">
          <Input.TextArea rows={3} placeholder="Optional notes" />
        </Form.Item>
      </Form>
    </Create>
  );
};
