import { MarkdownField } from "@pankod/refine-antd";
import { buildAttachmentUrl, RESOURCE_PATH, useAttachment } from "scripts/site";
import { RenderSpinner } from "./spinner";

export declare type mdProps = {
    mdFilePath: string;
    resourceName: string;
};

export const RenderMDFile: React.FC<mdProps> = ({
    mdFilePath,
    resourceName
}) => {

    const mdFileUrl = buildAttachmentUrl(RESOURCE_PATH.METADATA, resourceName, mdFilePath);
    const { response: mdData } = useAttachment(mdFileUrl);

    return (
        mdData ?
            <MarkdownField value={mdData} ></MarkdownField>
            :
            <RenderSpinner message="Loading content..." />
    );
};