import { Descriptions, Divider, getDefaultSortOrder, Icons, NumberField, Table, Tag, TextField, Typography, UrlField, useTable } from "@pankod/refine-antd";
import { CanAccess, CrudFilter } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { IContract, IProject } from "interfaces";
import { DATAPROVIDER_DEFAULT, RESOURCE_PATH } from "scripts/site";

const { Text } = Typography;

export const ShowContract: React.FC<IContract> = (record) => {

    const assignedFilter: CrudFilter[] = [{
        field: "contractId",
        operator: "eq",
        value: record.id
    }];

    const { tableProps, sorter, tableQueryResult } = useTable<IProject>({
        dataProviderName: DATAPROVIDER_DEFAULT,
        resource: RESOURCE_PATH.PROJECT,
        initialSorter: [{
            field: "updatedAt",
            order: "desc"
        }],

        permanentFilter: assignedFilter,
        queryOptions: {
            enabled: !!record.id
        },
        syncWithLocation: false,
        initialPageSize: 100,

    });

    const totalProjects = tableQueryResult?.data?.total ?? 0;
    return (
        <>
            <Descriptions labelStyle={{ fontWeight: "bold" }} size="small">
                <Descriptions.Item label="Account Manager" >
                    <ShowUserCard id={record?.accountManager}></ShowUserCard>
                </Descriptions.Item>
                <Descriptions.Item label="Delivery Head">
                    <ShowUserCard id={record?.deliveryHead}></ShowUserCard>
                </Descriptions.Item>
                <Descriptions.Item label="Delivery Partner" >
                    <ShowUserCard id={record?.deliveryPartner}></ShowUserCard>
                </Descriptions.Item>
                <Descriptions.Item label="Processing Personal Data">
                    <Text>{record?.personalDataProcessing}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="GDPR Addendum">
                    <Text>{record?.gdprAddendum}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="PMO" >
                    <ShowUserCard id={record?.pmo}></ShowUserCard>
                </Descriptions.Item>
            </Descriptions>
            <CanAccess resource={RESOURCE_PATH.PROJECT} action="list">
                <Divider dashed orientation="left">
                    <Tag color={tableQueryResult.isLoading ? "default" : (totalProjects === 0 ? "red" : "blue")}>
                        {tableQueryResult.isLoading ? "Loading" : totalProjects} Project{totalProjects !== 1 && "s"}
                    </Tag>
                </Divider>
                {totalProjects > 0 &&
                    <>
                        <Table {...tableProps}
                            rowKey="id"
                            pagination={{
                                ...tableProps.pagination,
                                hideOnSinglePage: true,
                                position: ["bottomRight"]
                            }}
                            //scroll={{ y: 500 }}
                            //sticky
                            summary={pageData => {
                                const fiscalTotal = {
                                    q1: 0,
                                    q2: 0,
                                    q3: 0,
                                    q4: 0,
                                    nq1: 0,
                                }

                                pageData.forEach(({ fiscal }) => {
                                    fiscalTotal.q1 += fiscal.q1;
                                    fiscalTotal.q2 += fiscal.q2;
                                    fiscalTotal.q3 += fiscal.q3;
                                    fiscalTotal.q4 += fiscal.q4;
                                    fiscalTotal.nq1 += fiscal.nq1;
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={5}>
                                                <Typography.Text strong>Total</Typography.Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={5}>
                                                <NumberField strong options={{
                                                    currency: "USD",
                                                    style: "currency",
                                                    notation: "compact"
                                                }}
                                                    title={fiscalTotal?.q1?.toString()}
                                                    value={fiscalTotal?.q1 || 0} />
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={6}>
                                                <NumberField strong options={{
                                                    currency: "USD",
                                                    style: "currency",
                                                    notation: "compact"
                                                }}
                                                    title={fiscalTotal?.q2?.toString()}
                                                    value={fiscalTotal?.q2 || 0} />
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={7}>
                                                <NumberField strong options={{
                                                    currency: "USD",
                                                    style: "currency",
                                                    notation: "compact"
                                                }}
                                                    title={fiscalTotal?.q3?.toString()}
                                                    value={fiscalTotal?.q3 || 0} />
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={8}>
                                                <NumberField strong options={{
                                                    currency: "USD",
                                                    style: "currency",
                                                    notation: "compact"
                                                }}
                                                    title={fiscalTotal?.q4?.toString()}
                                                    value={fiscalTotal?.q4 || 0} />
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={9}>
                                                <NumberField strong options={{
                                                    currency: "USD",
                                                    style: "currency",
                                                    notation: "compact"
                                                }}
                                                    title={fiscalTotal?.nq1?.toString()}
                                                    value={fiscalTotal?.nq1 || 0} />
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={10}>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        >
                            <Table.Column
                                dataIndex="name"
                                key="name"
                                title="Name"
                                render={(value, record: IProject) => <>
                                    {value}
                                    <UrlField onClick={(e) => e.stopPropagation()} target={"_blank"} value={`https://dev.azure.com/tcscorpip/CorePipe/_workitems/edit/${record?.adoId}`}>
                                        <Icons.SelectOutlined title="View on ADO Core Pipe" rotate={90} style={{ marginLeft: 5 }} />
                                    </UrlField>
                                </>}
                                defaultSortOrder={getDefaultSortOrder("name", sorter)}
                                sorter />
                            <Table.Column
                                dataIndex="won"
                                key="won"
                                title="WON"
                                render={(value: string) => <TextField value={value} />}
                                sorter
                                defaultSortOrder={getDefaultSortOrder("won", sorter)} />

                            <Table.Column
                                dataIndex="state"
                                key="state"
                                title="State"
                                render={(value: string) => <TextField value={value} />}
                                sorter
                                defaultSortOrder={getDefaultSortOrder("state", sorter)} />

                            <Table.Column
                                dataIndex="natureOfWork"
                                key="natureOfWork"
                                title="Nature Of Work"
                                render={(value: string) => <TextField value={value} />}
                                sorter
                                defaultSortOrder={getDefaultSortOrder("natureOfWork", sorter)} />

                            <Table.Column
                                dataIndex={["fiscal", "fiscalYear"]}
                                key="fiscal.fiscalYear"
                                title="Fiscal Year"
                                render={(value: string) => <TextField value={value} />}
                                sorter />

                            <Table.Column
                                dataIndex={["fiscal", "q1"]}
                                key="fiscal.q1"
                                title="Q1"
                                render={(value) => <NumberField
                                    options={{
                                        currency: "USD",
                                        style: "currency",
                                        notation: "compact"
                                    }}
                                    title={value}
                                    value={value} />}
                                sorter />

                            <Table.Column
                                dataIndex={["fiscal", "q2"]}
                                key="fiscal.q2"
                                title="Q2"
                                render={(value) => <NumberField
                                    options={{
                                        currency: "USD",
                                        style: "currency",
                                        notation: "compact"
                                    }}
                                    title={value}
                                    value={value} />}
                                sorter />
                            <Table.Column
                                dataIndex={["fiscal", "q3"]}
                                key="fiscal.q3"
                                title="Q3"
                                render={(value) => <NumberField
                                    options={{
                                        currency: "USD",
                                        style: "currency",
                                        notation: "compact"
                                    }}
                                    title={value}
                                    value={value} />}
                                sorter />

                            <Table.Column
                                dataIndex={["fiscal", "q4"]}
                                key="fiscal.q4"
                                title="Q4"
                                render={(value) => <NumberField
                                    options={{
                                        currency: "USD",
                                        style: "currency",
                                        notation: "compact"
                                    }}
                                    title={value}
                                    value={value} />}
                                sorter />
                            <Table.Column
                                dataIndex={["fiscal", "nq1"]}
                                key="fiscal.nq1"
                                title="NQ1"
                                render={(value) => <NumberField
                                    options={{
                                        currency: "USD",
                                        style: "currency",
                                        notation: "compact"
                                    }}
                                    title={value}
                                    value={value} />}
                                sorter />
                            <Table.Column
                                dataIndex="updatedAt"
                                key="updatedAt"
                                title="Last Updated"
                                render={displayRelativeDate}
                                defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
                                sorter />
                        </Table>
                    </>
                }
            </CanAccess>

        </>
    );
};
