import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space } from "@pankod/refine-antd";

export const ButtonDropdown: React.FC<{ dropdownItems: any[] }> = ({ dropdownItems }) => {

    const itemsLength = dropdownItems.length;
    const menu = (
        <Menu items={dropdownItems} />
    );

    // Single item in the list
    if (itemsLength === 1) {
        const buttonText = dropdownItems?.[0].label.props.children;
        const defaultStyle = dropdownItems?.[0].label.props.style

        return (
            <Space>
                <Button style={Object.assign(defaultStyle)} className="stt-btn-sngl" onClick={(e) => {
                    e.preventDefault();
                    return dropdownItems?.[0].label.props.onClick(buttonText);
                }}>
                    {buttonText}
                </Button>
            </Space>
        );
    }
    else if (itemsLength > 1) {
        return (
            <Space>
                <Dropdown overlay={menu}>
                    <Button onClick={(e) => e.preventDefault()}>
                        <Space>
                            Available Actions
                            <CaretDownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            </Space>
        );
    }
    else {
        return (
            <></>
        );
    }
};