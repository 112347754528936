import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import {
  Alert,
  Create,
  DatePicker,
  Form, InputNumber, Radio, SaveButton, Select, useForm, useRadioGroup, useSelect
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation, useOne } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import { LookupButton } from "components/utils/lookupButton";
import { Dayjs } from "dayjs";
import { IAllocationMetadata, IAssociateMapping, ILookup } from "interfaces";
import { UserType } from "interfaces/enum";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { DATAPROVIDER_DEFAULT, DATAPROVIDER_LOOKUP, getQueryStringParams, RESOURCE_PATH } from "scripts/site";

export const AllocationCreate: React.FC<IResourceComponentsProps> = () => {
  //const { push } = useNavigation();
  const { search } = useLocation();
  const { engagementId } = getQueryStringParams(search);
  const { goBack } = useNavigation();

  const { formProps, saveButtonProps } = useForm<IAssociateMapping>({
    dataProviderName: DATAPROVIDER_DEFAULT,
    redirect: false,
  });

  const { data: entityMetadata } = useOne<IAllocationMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ASSOCIATEMAPPING,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });

  const metaConfig = entityMetadata?.data?.config;

  const { selectProps: userProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PROFILE,
    optionLabel: "nameEmail",
    optionValue: "id",
    sort: [{
      field: "lastAccessedAt",
      order: "desc"
    }],
    filters: [{
      field: "userType",
      operator: "eq",
      value: UserType.Employee
    },
      //   {
      //   field: "engagements ARRAY_ALLOW_NULL_ALL startDate",
      //   operator: "lte",
      //   value: "2022-08-07"
      // },
      // {
      //   field: "_nullfields",
      //   operator: "eq",
      //   value: "engagements"
      // }
    ]
  });

  const [startDate, setStartDate] = useState<Dayjs>();

  const { radioGroupProps: locationProps } = useRadioGroup<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.LOCATION,
    optionLabel: "name",
    optionValue: "id",
    sort: [
      {
        field: "name",
        order: "asc"
      },
    ],
  });

  return (
    <Create //saveButtonProps={saveButtonProps}
      title="Map Associates"
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
          <CancelButton />
        </>
      )}
    // actionButtons={
    //   <>
    //     <SaveButton  {...saveButtonProps} />
    //     <CancelButton />
    //   </>
    // }
    >
      <Form {...formProps} layout="vertical" autoComplete="off"
        initialValues={{ engagementId: engagementId }}
        onFinish={(values) => {
          const mapping = values as IAssociateMapping;

          if (mapping) {
            (mapping.userIds ?? []).forEach(userId => {
              formProps?.onFinish && formProps?.onFinish({ ...mapping, userId, userIds: undefined });
            });
            goBack();
          }
        }}
      >
        <Form.Item
          //initialValue={engagementId}
          label="Engagement"
          name="engagementId"
        >
          <LookupButton id={engagementId || ""} resource={RESOURCE_PATH.ENGAGEMENTS}></LookupButton>
        </Form.Item>

        <Form.Item
          label="Associates"
          name="userIds"
          rules={[
            {
              required: true,
              whitespace: true,
              type: "array"
            },
          ]}
        >
          <Select placeholder="Search Name or Email" mode="multiple" {...userProps} allowClear />
        </Form.Item>

        <Form.Item
          label="Location"
          name="locationId"
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Radio.Group optionType="button"
            buttonStyle="solid"
            {...locationProps}>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Start Date"
          name="startDate"
          rules={[
            {
              required: true,
              whitespace: true,
              type: "date"
            }
          ]}
        >
          <DatePicker picker="date"
            style={{ width: 280 }}
            //disabledDate={(selDate) => selDate && selDate < minStartDate}
            onChange={(val) => val && setStartDate(val)}
          />
        </Form.Item>

        <Form.Item
          label="End Date"
          name="endDate"
          rules={[
            {
              //required: true,
              whitespace: true,
              type: "date"
            }
          ]}
        >
          <DatePicker picker="date"
            allowClear
            style={{ width: 280 }}
            disabledDate={(selDate) => selDate && !!startDate && selDate < startDate}
          />
        </Form.Item>

        <Form.Item
          label="Role"
          name="role"
          rules={[
            {
              required: true,
              whitespace: true
            },
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={metaConfig?.roles?.map(ut => ({
              label: stringExtensions.capitalize(ut.toString()),
              value: ut.toString()
            }))}
          />
        </Form.Item>

        <Form.Item
          label="Allocation Percentage"
          name="percentAllocation"
          rules={[
            {
              required: true,
              whitespace: true,
              type: "number"
            },
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={metaConfig?.percentAllocation?.map(ut => ({
              label: ut + "%",
              value: ut
            }))}
          />
        </Form.Item>

        <Form.Item
          label="Bill Rate"
          name="billRateUSD"
          rules={[
            {
              required: true,
              whitespace: true,
              type: "number",
            },
          ]}
        >
          <InputNumber maxLength={3} controls={false} addonAfter={"USD/hour"} />
        </Form.Item>

        <Alert
          //message="Informational Note"
          message={"Mapping of associates are NOT validated. Please ensure that the associates are eligible for the engagement and location."}
          type="info"
          showIcon
        />

      </Form>
    </Create >
  );
};
