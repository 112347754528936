import { Button, Col, Form, Input, Row, Select, Typography, useForm } from "@pankod/refine-antd";
import { useCan, useOne } from "@pankod/refine-core";
import { IEngagement, IExpertConnect, IExpertConnectMetadata, IOutreachTexts } from "interfaces";
import { useEffect, useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_OUTREACH, RESOURCE_PATH } from "scripts/site";
import { RenderSpinner } from "./spinner";

export declare type outreachFormProps = {
    engagement?: IEngagement,
    categoryType?: string,
    formLabels?: IOutreachTexts
};

export const OutreachForm: React.FC<outreachFormProps> = ({
    categoryType,
    formLabels = {
        errorMessage: "Unable to process your request. Please retry. If problem persists, please reach out to helium support team.",
        successMessage: "Your query is successfully directed to expert.",
        disciplineText: "Discipline",
        expertiseText: "Field of Expertise",
        subjectText: "Topic",
        questionText: "Question",
        additionalText: "Addition details",
        buttonText: "Send Query"

    }
}) => {
    const { isLoading, data: metadata } = useOne<IExpertConnectMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.EXPERT_OUTREACH,
        queryOptions: {
            enabled: true,
            staleTime: 300000,
        }
    });

    const expertAreas = metadata?.data.config.expertAreas;
    const [expertDiscipline, setExpertDiscipline] = useState("");
    const [formTextLabels, setFormLabels] = useState(formLabels);

    const { form, formProps, saveButtonProps } = useForm<IExpertConnect>({
        dataProviderName: DATAPROVIDER_OUTREACH,
        resource: RESOURCE_PATH.EXPERT_OUTREACH,
        redirect: false,
        successNotification: { type: "success", message: formTextLabels.successMessage },
        errorNotification: { type: "error", message: formTextLabels.errorMessage }
    });

    useEffect(() => {
        // Passed with Category, load only specific category details
        if (categoryType) {
            let area = expertAreas?.find(x => x.id === categoryType);
            setExpertDiscipline(area?.category || "");
            if (area?.formTexts) {
                setFormLabels(area.formTexts);
            }
            form.setFieldsValue({
                category: area?.category
            })
        }
    }, [categoryType, expertAreas, form, isLoading])

    const canListExpertOutreach = useCan({
        resource: RESOURCE_PATH.EXPERT_OUTREACH,
        action: "create"
    });

    return (canListExpertOutreach.data?.can ?
        (isLoading ?
            <RenderSpinner message="Loading connect form..." />
            :
            <Form layout="vertical" form={form} {...formProps} autoComplete="off">
                <Row gutter={[10, 0]} align="bottom">
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label={formTextLabels.disciplineText}
                            name="category"
                            hidden={categoryType ? true : false}
                            rules={[
                                {
                                    required: true,
                                    validator(_rule, value) {
                                        let initialAreaCategory = expertDiscipline;
                                        setExpertDiscipline(value)
                                        if (initialAreaCategory !== value) {
                                            form.resetFields(['primaryExpertArea']);
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Select area of connect"
                                disabled={categoryType ? true : false}
                            >
                                {expertAreas?.sort().map((m, i) => (
                                    <Select.Option value={m.category} key={i}>{m.category}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label={formTextLabels.expertiseText}
                            name="primaryExpertArea"
                            dependencies={['category']}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder={formTextLabels.expertiseText}
                            >
                                {expertAreas?.find(x => x.category === expertDiscipline)?.areas.sort().map((m, i) => (
                                    <Select.Option value={m.name} key={i}>{m.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label={formTextLabels.subjectText}
                            name="subject"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    min: 10,
                                    max: 50
                                },
                            ]}
                        >
                            <Input placeholder="Intent of connect in short" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label={formTextLabels.questionText}
                            name="question"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    min: 20,
                                    max: 500
                                },
                            ]}
                        >
                            <Input.TextArea rows={3} placeholder="Describe the feedback/question/issue and help required" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label={formTextLabels.additionalText}
                            name="additionalInformation"
                            rules={[
                                {
                                    required: false,
                                    whitespace: true,
                                    min: 50,
                                    max: 1000
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} placeholder="Describe the issue, cause and effects" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item>
                            <Button
                                {...saveButtonProps}
                                style={{ width: "100%" }}
                                htmlType="submit"
                                type="primary"
                            >
                                {formTextLabels.buttonText}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>)
        : <Typography.Title level={4} style={{ color: "#e0529c" }} >You are not authorized to perform this operation</Typography.Title >
    );
}