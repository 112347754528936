import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import {
  Button, Checkbox, Divider, Edit, Form, getValueFromEvent, Icons, Input, InputNumber, message,
  Radio, RcFile, SaveButton, Select, Steps, Tag, Typography, Upload, useFileUploadState, useRadioGroup,
  useStepsForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useOne } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import { IListData, ILookup, IUser } from "interfaces";
import { UserType } from "interfaces/enum";
import { useEffect, useState } from "react";
import { DATAPROVIDER_DEFAULT, DATAPROVIDER_LOOKUP, DATAPROVIDER_UPLOAD, MY_PROFILE, removeLocalSessionStorage, RESOURCE_PATH, SELECTION_LISTS } from "scripts/site";

export const ProfileEdit: React.FC<IResourceComponentsProps> = () => {
  const { current, formProps, stepsProps, saveButtonProps, queryResult } = useStepsForm<IUser>({
    dataProviderName: DATAPROVIDER_DEFAULT,
    onMutationSuccess: () => { removeLocalSessionStorage(MY_PROFILE) }
  });

  const { radioGroupProps: locationProps } = useRadioGroup<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.LOCATION,
    optionLabel: "name",
    optionValue: "id",
    sort: [
      {
        field: "name",
        order: "asc"
      },
    ],
  });
  const userType = queryResult?.data?.data?.userType;

  const [contextualMaster, setContextualMaster] = useState(false);
  const [imageList, setImageList] = useState([{ label: "No Image Uploaded", value: "no-image" }]);
  const { Step } = Steps;
  const apiUrl = useApiUrl(DATAPROVIDER_UPLOAD);
  const { isLoading: isUploading, onChange: onUploadChange } = useFileUploadState();

  useEffect(() => {

    let usrImages = queryResult?.data?.data?.userImages || [];
    setImageList(usrImages?.map(ut => ({ label: stringExtensions.capitalize(ut.name), value: ut.uid })) || []);
    setContextualMaster(queryResult?.data?.data?.employee?.contextualMaster || false);

  }, [queryResult?.isFetching, queryResult?.data?.data?.userImages, queryResult?.data?.data?.employee?.contextualMaster])

  const { data: hobbiesList } = useOne<IListData>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.LIST,
    id: SELECTION_LISTS.HOBBIES
  });

  const { data: technicalExpertiseList } = useOne<IListData>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.LIST,
    id: SELECTION_LISTS.TECHNICAL_EXPERTISE
  });

  const { data: functionalExpertiseList } = useOne<IListData>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.LIST,
    id: SELECTION_LISTS.FUNCTIONAL_EXPERTISE
  });

  const formList = [
    <>
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            whitespace: true,
            max: 100
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Location"
        name="locationId"
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Radio.Group optionType="button"
          buttonStyle="solid"
          {...locationProps}>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Email"
        name="id"
        valuePropName="children"
        tooltip="Non editable field"
      >
        <Typography.Text copyable></Typography.Text>
      </Form.Item>
      <Form.Item
        label="User Type"
        name="userType"
        tooltip="Non editable field. Please reach out to PMO Team for making an update to this field."
      >
        <Typography.Text>{stringExtensions.capitalize(userType ?? "")}</Typography.Text>
      </Form.Item>
    </>,
    <>
      <Form.Item
        label="About Me"
        name="aboutMe"
        rules={[
          {
            required: false,
            whitespace: true,
            max: 250
          }
        ]}
      >
        <Input.TextArea rows={3} placeholder="Short abstract introducing yourself to your colleagues" />
      </Form.Item>
      <Form.Item
        label="Hobbies"
        name="hobbies"
        rules={[
          {
            required: false,
            type: "array",
          }
        ]}
      >
        <Select mode="tags"
          placeholder="Type your hobby and presss enter. You can add as many as you want."
          tokenSeparators={[',']}
        >
          {hobbiesList?.data.data.map((m, i) => (
            <Select.Option value={m} key={i}>{m}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Area of Technical Expertise"
        name="technicalExpertiseAreas"
        rules={[
          {
            required: false,
            type: "array",
          }
        ]}
      >
        <Select mode="tags"
          placeholder="Type area of expertise and presss enter. You can add as many as you want."
          tokenSeparators={[',']}
        >
          {technicalExpertiseList?.data.data.map((m, i) => (
            <Select.Option value={m} key={i}>{m}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Area of Functional Expertise"
        name="functionalExpertiseAreas"
        rules={[
          {
            required: false,
            type: "array",
          }
        ]}
      >
        <Select
          mode="tags"
          placeholder="Type area of expertise and presss enter. You can add as many as you want."
          tokenSeparators={[',']}
        >
          {functionalExpertiseList?.data.data.map((m, i) => (
            <Select.Option value={m} key={i}>{m}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Divider orientation="left">Media Section</Divider>
      <Tag color="yellow" style={{ marginBottom: 10 }}><b>Note:</b> Uploading images is optional. By uploading image you agree to abide by Organization's Media usage Policy and Helium's terms of use.</Tag>
      <Form.Item
        label="Upload images to be used as profile images"
        name="userImages"
        valuePropName="fileList"
        getValueFromEvent={getValueFromEvent}
        tooltip="Upload upto 3 images to choose as profile images from."
        rules={[
          {
            required: false,
            validator(_, value) {
              if (value) {
                setImageList(value.map((ut: any) => ({ label: stringExtensions.capitalize(ut.name), value: ut.uid })))
              }
              return Promise.resolve();
            }
          }
        ]}
      >
        <Upload
          name="file"
          accept="image/*"
          action={`${apiUrl}/profile/image`}
          listType="picture"
          onChange={onUploadChange}
          maxCount={3}
          multiple
          beforeUpload={(file: RcFile) => {
            const isImageFile = file.type.search('image') === 0;
            if (!isImageFile) {
              message.error(`${file.name} is not an image file!`);
            }
            const isLt2M = (file.size / 1024 / 1024) < 2;
            if (!isLt2M) {
              message.error(`${file.name} is greater than 2MB!`);
            }
            return isImageFile && isLt2M;
          }}
        >
          <Button icon={<Icons.UploadOutlined />}>Upload (Max: 3)</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        label="Profile Image"
        name="userProfileImage"
        rules={[
          {
            required: false,
            whitespace: true
          }
        ]}
      >
        <Select placeholder="Select your profile image from list of uploaded images" >
          {imageList.map((m, i) => (
            <Select.Option value={m.value} key={i}>{m.label}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Metaverse Avatar"
        name="userMetaverseAvatar"
        rules={[
          {
            required: false,
            whitespace: true
          }
        ]}
      >
        <Select placeholder="Select your Metaverse avatar from list of uploaded images">
          {imageList.map((m, i) => (
            <Select.Option value={m.value} key={i}>{m.label}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  ];

  if (userType && userType === UserType.Employee) {
    formList.push(<>
      <Divider orientation="left">Employee basics</Divider>
      <Form.Item
        label="TCS Employee ID"
        name={["employee", "employeeId"]}
        rules={[
          {
            required: true,
            whitespace: true,
            type: "number",
          },
        ]}
      >
        <InputNumber maxLength={7} controls={false} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        label="TCS Card number"
        name={["employee", "cardNumber"]}
        rules={[
          {
            required: true,
            whitespace: true,
            type: "string",
          },
        ]}
      >
        <Input maxLength={10} />
      </Form.Item>
      <Form.Item
        label="Alternate Email"
        name={["employee", "alternateEmail"]}
        rules={[
          {
            type: "email",
            whitespace: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Phone number"
        name={["employee", "phoneNumber"]}
        rules={[
          {
            required: true,
            whitespace: true,
            type: "string"
          },
        ]}
      >
        <InputNumber maxLength={12} controls={false} stringMode style={{ width: '100%' }} />
      </Form.Item>
      <Divider orientation="left">Office basics</Divider>
      <Form.Item
        label="Working From"
        name={["employee", "workingFrom"]}
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Radio.Group buttonStyle="solid">
          <Radio.Button value="Home">Home</Radio.Button>
          <Radio.Button value="TCS Office">TCS Office</Radio.Button>
          <Radio.Button value="Client Office">Client Office</Radio.Button>
          <Radio.Button value="Hybrid">Hybrid</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Building Access"
        name={["employee", "buildingAccess"]}
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Select>
          <Select.Option value="Able to access TCS and ODC">Able to access TCS and ODC</Select.Option>
          <Select.Option value="No access to TCS and ODC">No access to TCS and ODC</Select.Option>
          <Select.Option value="No access to TCS office">No access to TCS office</Select.Option>
          <Select.Option value="No access to ODC">No access to ODC</Select.Option>
          <Select.Option value="No Identity card, picture uploaded">No Identity card, picture uploaded</Select.Option>
          <Select.Option value="Do not know">Do not know</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Desk"
        name={["employee", "desk"]}
        rules={[
          {
            whitespace: true,
            type: "string",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Divider orientation="left">Transport basics</Divider>
      <Form.Item
        label="Vehicle Make/Model"
        name={["employee", "vehicleMakeModel"]}
        rules={[
          {
            type: "string",
            whitespace: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Vehicle Color"
        name={["employee", "vehicleColor"]}
        rules={[
          {
            type: "string",
            whitespace: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Vehicle License Plate Number"
        name={["employee", "vehicleLicense"]}
        rules={[
          {
            type: "string",
            whitespace: true
          }
        ]}
      >
        <Input />
      </Form.Item>
    </>);

    formList.push(<>
      <Form.Item
        label="Are you a Contextual Master?"
        name={["employee", "contextualMaster"]}
        valuePropName="checked"
        rules={[
          {
            required: false,
            type: "boolean"
          }
        ]}
      >
        <Checkbox checked={contextualMaster} onChange={() => setContextualMaster(!contextualMaster)} />
      </Form.Item>
      <Form.Item
        label="Contextual Master Story Link"
        name={["employee", "contextualMasterStory"]}
        dependencies={["employee", "contextualMaster"]}
        hidden={!contextualMaster}
        rules={[
          {
            required: contextualMaster,
            type: "url"
          },
        ]}
      >
        <Input placeholder="Please provide published contextual master Knome link" />
      </Form.Item>
      <Form.Item
        label="Technical Certifications"
        name={["employee", "technicalCertificationList"]}
        rules={[
          {
            required: false,
            type: "array",
          }
        ]}
      >
        <Select mode="tags" placeholder="Type your certification and presss enter. You can add as many as you have." tokenSeparators={[',']}>
        </Select>
      </Form.Item>
      <Form.Item
        label="Functional Certifications"
        name={["employee", "functionalCertificationList"]}
        rules={[
          {
            required: false,
            type: "array",
          }
        ]}
      >
        <Select mode="tags" placeholder="Type your certification and presss enter. You can add as many as you have." tokenSeparators={[',']}>
        </Select>
      </Form.Item>
    </>);
  }

  return (
    <Edit
      isLoading={queryResult?.isFetching}
      pageHeaderProps={{
        extra: <></>
      }}
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} disabled={isUploading} />
          <CancelButton />
        </>
      )}
    >
      <Divider dashed />
      <Steps responsive {...stepsProps} >
        <Step
          key="basic"
          title="Basic Details"
        />
        <Step
          key="aboutme"
          title="About Me"
        />
        {userType === UserType.Employee &&
          <>
            <Step
              key="employee"
              title="Employee Details"
            />
            <Step
              key="randr"
              title="Rewards and Recognition"
            />
          </>
        }
      </Steps>
      <Divider dashed />
      <Form
        {...formProps}
        autoComplete="off"
        layout="vertical"
      >
        {formList[current]}
      </Form>
    </Edit>
  );
};
