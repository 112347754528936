import { Tooltip, Typography } from "@pankod/refine-antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export function displayRelativeDate(date?: dayjs.ConfigType) {
    return (
        <Tooltip title={dayjs(date).format("lll")}>
            <Typography.Text style={{ color: "GrayText" }}> {dayjs(date).fromNow()} </Typography.Text>
        </Tooltip>
    );
}

export function displayElapsedTime(date?: dayjs.ConfigType) {
    return (
        <Tooltip title={dayjs(date).format("lll")}>
            <Typography.Text style={{ color: "GrayText" }}> {dayjs(date).toNow().replace("in", "")} </Typography.Text>
        </Tooltip>
    );
}