import { UnorderedListOutlined } from "@ant-design/icons";
import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { AntdLayout, Grid, Menu } from "@pankod/refine-antd";
import {
    CanAccess,
    ITreeMenu, useAuthenticated, useNavigation, useTitle, useMenu
} from "@pankod/refine-core";
import { IAuthZ } from "interfaces";
import React, { CSSProperties, useState } from "react";
import { AAD_LOGOUT, allowedResources, useAuthZ } from "scripts/site";

const { SubMenu } = Menu;

export const RefineMenu: React.FC = () => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const Title = useTitle();
    const { menuItems, selectedKey } = useMenu();
    const { push } = useNavigation();
    const breakpoint = Grid.useBreakpoint();

    const isMobile = !breakpoint.lg;
    const { isSuccess: isAuthenticated } = useAuthenticated();

    const { authZ, isLoading } = useAuthZ();
    const isFetched = !isLoading;

    const checkShowMenu = (meta: IAuthZ): boolean => {
        let result = true;
        if (meta && meta.ui && meta.ui.showMenu === false) {
            result = false;
        }
        return result;
    }

    let filteredMenu: ITreeMenu[] = [];

    const filterMenuItems = (items: ITreeMenu[]): ITreeMenu[] => {
        const result: ITreeMenu[] = [];
        items?.forEach(mi => {
            if (mi?.children?.length > 0) {
                const childResult = filterMenuItems(mi.children);
                if (childResult?.length > 0) {
                    mi.children = childResult;
                    result.push(mi);
                }
            }
            else if (allowedResources.some(m => m === mi.name) || authZ?.some(m => m.id === mi.name && checkShowMenu(m))) {
                result.push(mi);
            }
        });
        return result;
    }

    if (isAuthenticated && isFetched && arrayExtensions.validateArray(authZ)) {
        filteredMenu = filterMenuItems(menuItems);
    }

    const splitKeys = selectedKey.split("/");
    let selectedItem = splitKeys[splitKeys.length - 1];
    const selItemMeta = authZ?.find(auth => auth.id === selectedItem);
    if (selItemMeta && selItemMeta.ui?.showMenu === false) {
        selectedItem = "/" + splitKeys.slice(1, -1).concat(selItemMeta.ui?.menuKey).join("/");
    }
    else {
        // No change in the key is required 
        selectedItem = selectedKey;
    }

    const renderTreeView = (tree: ITreeMenu[], selKey: string) => {
        return tree.map((item: ITreeMenu) => {
            const { icon, label, route, name, children, parentName } = item;

            if (children.length > 0) {
                return (
                    <SubMenu
                        key={name}
                        icon={icon ?? <UnorderedListOutlined />}
                        title={label}
                    >
                        {renderTreeView(children, selKey)}
                    </SubMenu>
                );
            }
            const isSelected = route === selKey;
            const isRoute = !(
                parentName !== undefined && children.length === 0
            );

            return (
                <CanAccess
                    key={route}
                    resource={name.toLowerCase()}
                    action="list"
                >
                    <Menu.Item
                        key={route}
                        onClick={() => {
                            push(route ?? "");
                        }}
                        style={{
                            fontWeight: isSelected ? "bold" : "normal",
                        }}
                        icon={icon ?? (isRoute && <UnorderedListOutlined />)}
                    >
                        {label}
                        {!collapsed && isSelected && (
                            <div className="ant-menu-tree-arrow" />
                        )}
                    </Menu.Item>
                </CanAccess>
            );
        });
    };

    return (
        <AntdLayout.Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
            collapsedWidth={isMobile ? 0 : 80}
            breakpoint="lg"
            style={isMobile ? antLayoutSiderMobile : antLayoutSider}
        >
            {Title ? <Title collapsed={collapsed} /> : <></>}
            <Menu theme="light"
                selectedKeys={[selectedItem]}
                defaultOpenKeys={['Home']}
                mode="inline"
                onClick={({ key }) => {
                    if (key === "logout") {
                        window.location.href = AAD_LOGOUT;
                        return;
                    }

                    if (!breakpoint.lg) {
                        setCollapsed(true);
                    }

                    push(key as string);
                }}
            >
                {renderTreeView(filteredMenu, selectedItem)}
            </Menu>
        </AntdLayout.Sider>
    );
};

export const antLayoutSider: CSSProperties = {
    position: "relative",
};
export const antLayoutSiderMobile: CSSProperties = {
    position: "fixed",
    height: "100vh",
    zIndex: 999,
};

//Code adapted from: https://github.com/pankod/refine/blob/8b8da6091776e17cdfa683857176d57285908344/examples/customization/customSider/src/components/sider/index.tsx