import { ILookup } from "@architecture-innovation-transformation/lib-common";
import { Form, FormInstance, Radio, Spin, useRadioGroup } from "@pankod/refine-antd";
import { ICase } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const CaseFieldsRadio: React.FC<{ record?: ICase, isLoading: boolean, form: FormInstance }> = ({ record, isLoading, form }) => {

    const { radioGroupProps: businessFunctionProps, queryResult: businessFunctionQuery } = useRadioGroup<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.BUSINESS_FUNCTION,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        queryOptions: {
            //staleTime: 300000,
        }
    });
    const [businessFunc, setBusinessFunc] = useState(record?.businessFunctionId ?? "");

    const { radioGroupProps: lineOfBusinessProps, queryResult: lineOfBusinessQuery } = useRadioGroup<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.LINE_OF_BUSINESS,
        optionLabel: "name",
        optionValue: "id",
        filters: [{
            field: "parentId",
            operator: "eq",
            value: businessFunc
        }],
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        queryOptions: {
            //enabled: (businessFunc !== undefined && businessFunc !== ""),
            //staleTime: 300000,
        }
    });
    const [lineOfBusiness, setLineOfBusiness] = useState(record?.lineOfBusinessId ?? "");

    const { radioGroupProps: programProps, queryResult: programQuery } = useRadioGroup<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PROGRAM,
        optionLabel: "name",
        optionValue: "id",
        filters: [{
            field: "parentId",
            operator: "eq",
            value: lineOfBusiness
        }],
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        queryOptions: {
            //enabled: (lineOfBusiness !== undefined && lineOfBusiness !== ""),
            //staleTime: 300000,
        }
    });
    const [program, setProgram] = useState(record?.programId ?? "");

    const { radioGroupProps: caseTypeProps, queryResult: caseTypeQuery } = useRadioGroup<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.CASE_TYPE,
        optionLabel: "name",
        optionValue: "id",
        filters: [{
            field: "parentId",
            operator: "eq",
            value: program
        }],
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        queryOptions: {
            //enabled: (program !== undefined && program !== ""),
            //staleTime: 300000,
        }
    });
    const [caseType, setCaseType] = useState(record?.caseTypeId ?? "");

    const { radioGroupProps: subCaseTypeProps, queryResult: subCaseTypeQuery } = useRadioGroup<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.SUB_CASE_TYPE,
        optionLabel: "name",
        optionValue: "id",
        filters: [{
            field: "parentId",
            operator: "eq",
            value: caseType
        }],
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        queryOptions: {
            //enabled: (program !== undefined && program !== ""),
            //staleTime: 300000,
        }
    });
    const [subCaseType, setSubCaseType] = useState(record?.subCaseTypeId ?? "");

    const { radioGroupProps: caseReasonProps, queryResult: caseReasonQuery } = useRadioGroup<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.CASE_REASON,
        optionLabel: "name",
        optionValue: "id",
        filters: [{
            field: "parentId",
            operator: "eq",
            value: subCaseType
        }],
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        queryOptions: {
            //enabled: (program !== undefined && program !== ""),
            //staleTime: 300000,
        }
    });
    const [caseReason, setCaseReason] = useState(record?.caseReasonId ?? "");

    const { radioGroupProps: subReasonProps, queryResult: subReasonQuery } = useRadioGroup<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.SUB_REASON,
        optionLabel: "name",
        optionValue: "id",
        filters: [{
            field: "parentId",
            operator: "eq",
            value: caseReason
        }],
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        queryOptions: {
            //enabled: (program !== undefined && program !== ""),
            //staleTime: 300000,
        }
    });
    const [subReason, setSubReason] = useState(record?.subReasonId ?? "");

    const [isInitialLoad, setIsInitialLoad] = useState(true);
    if (isInitialLoad && !isLoading && record) {
        setIsInitialLoad(false);
        //setRecord(queryResult?.data?.data);
        setBusinessFunc(record?.businessFunctionId);
        setLineOfBusiness(record?.lineOfBusinessId);
        setProgram(record?.programId);
        setCaseType(record?.caseTypeId);
        setSubCaseType(record?.subCaseTypeId);
        setCaseReason(record?.caseReasonId);
        setSubReason(record?.subReasonId);
    }

    const ClearFormBelow = (rank: number) => {
        let indexRank: number = 1;

        if (rank < indexRank) {
            setBusinessFunc("");
            form.setFieldsValue({ "businessFunctionId": null });
        }
        indexRank++;
        if (rank < indexRank) {
            setLineOfBusiness("");
            form.setFieldsValue({ "lineOfBusinessId": null });
        }
        indexRank++;
        if (rank < indexRank) {
            setProgram("");
            form.setFieldsValue({ "programId": null });
        }
        indexRank++;
        if (rank < indexRank) {
            setCaseType("");
            form.setFieldsValue({ "caseTypeId": null });
        }
        indexRank++;
        if (rank < indexRank) {
            setSubCaseType("");
            form.setFieldsValue({ "subCaseTypeId": null });
        }
        indexRank++;
        if (rank < indexRank) {
            setCaseReason("");
            form.setFieldsValue({ "caseReasonId": null });
        }
        indexRank++;
        if (rank < indexRank) {
            setSubReason("");
            form.setFieldsValue({ "subReasonId": null });
        }
        indexRank++;
    }

    return (<>
        <Spin spinning={businessFunctionQuery.isRefetching || businessFunctionQuery.isLoading} className="field-loading-spinner">
            <Form.Item
                label="Business Function"
                name="businessFunctionId"
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        validator(_rule, val) {
                            if (val !== "") {
                                if (businessFunc) {
                                    return Promise.resolve();
                                }
                                else {
                                    return Promise.reject("Please select a value");
                                }
                            }
                            else {
                                return Promise.reject();
                            }
                        },
                    }
                ]}
            >
                <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    {...businessFunctionProps}
                    onChange={(event) => {
                        setBusinessFunc(event.target.value);
                        ClearFormBelow(1);
                    }}
                />
            </Form.Item>
        </Spin>

        {businessFunc &&
            <Spin spinning={lineOfBusinessQuery.isRefetching || lineOfBusinessQuery.isLoading} className="field-loading-spinner">
                <Form.Item
                    label="Line Of Business"
                    name="lineOfBusinessId"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            validator(_rule, val) {
                                if (val !== "") {
                                    if (lineOfBusiness) {
                                        return Promise.resolve();
                                    }
                                    else {
                                        return Promise.reject("Please select a value");
                                    }
                                }
                                else {
                                    return Promise.reject();
                                }
                            },
                        }
                    ]}
                >
                    <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        {...lineOfBusinessProps}
                        onChange={(event) => {
                            setLineOfBusiness(event.target.value);
                            ClearFormBelow(2);
                        }}
                    />
                </Form.Item>
            </Spin>
        }

        {lineOfBusiness &&
            <Spin spinning={programQuery.isRefetching || programQuery.isLoading} className="field-loading-spinner">
                <Form.Item
                    label="Program"
                    name="programId"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            validator(_rule, val) {
                                if (val !== "") {
                                    if (program) {
                                        return Promise.resolve();
                                    }
                                    else {
                                        return Promise.reject("Please select a value");
                                    }
                                }
                                else {
                                    return Promise.reject();
                                }
                            },
                        }
                    ]}
                >
                    {!(programQuery.isRefetching || programQuery.isLoading) &&
                        <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            {...programProps}
                            onChange={(event) => {
                                setProgram(event.target.value);
                                ClearFormBelow(3);
                            }}
                        />}
                </Form.Item>
            </Spin>
        }

        {program && //!caseTypeQuery.isRefetching &&
            <Spin spinning={caseTypeQuery.isRefetching || caseTypeQuery.isLoading} className="field-loading-spinner">
                <Form.Item
                    label="Case Type"
                    name="caseTypeId"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            validator(_rule, val) {
                                if (val !== "") {
                                    if (caseType) {
                                        return Promise.resolve();
                                    }
                                    else {
                                        return Promise.reject("Please select a value");
                                    }
                                }
                                else {
                                    return Promise.reject();
                                }
                            },
                        }
                    ]}
                >
                    {!(caseTypeQuery.isRefetching || caseTypeQuery.isLoading) &&
                        <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            {...caseTypeProps}
                            onChange={(event) => {
                                setCaseType(event.target.value);
                                ClearFormBelow(4);
                            }}
                        />}
                </Form.Item>
            </Spin>
        }

        {caseType &&
            <Spin spinning={subCaseTypeQuery.isRefetching || subCaseTypeQuery.isLoading} className="field-loading-spinner">
                <Form.Item
                    label="Sub Case Type"
                    name="subCaseTypeId"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            validator(_rule, val) {
                                if (val !== "") {
                                    if (subCaseType) {
                                        return Promise.resolve();
                                    }
                                    else {
                                        return Promise.reject("Please select a value");
                                    }
                                }
                                else {
                                    return Promise.reject();
                                }
                            },
                        }
                    ]}
                >
                    {!(subCaseTypeQuery.isRefetching || subCaseTypeQuery.isLoading) &&
                        <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            {...subCaseTypeProps}
                            onChange={(event) => {
                                setSubCaseType(event.target.value);
                                ClearFormBelow(5);
                            }}
                        />
                    }
                </Form.Item>
            </Spin>
        }

        {subCaseType &&
            <Spin spinning={caseReasonQuery.isRefetching || caseReasonQuery.isLoading} className="field-loading-spinner">
                <Form.Item
                    label="Case Reason"
                    name="caseReasonId"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            validator(_rule, val) {
                                if (val !== "") {
                                    if (caseReason) {
                                        return Promise.resolve();
                                    }
                                    else {
                                        return Promise.reject("Please select a value");
                                    }
                                }
                                else {
                                    return Promise.reject();
                                }
                            },
                        }
                    ]}
                >
                    {!(caseReasonQuery.isRefetching || caseReasonQuery.isLoading) &&
                        <Radio.Group
                            //value={caseReason}
                            optionType="button"
                            buttonStyle="solid"
                            {...caseReasonProps}
                            onChange={(event) => {
                                setCaseReason(event.target.value);
                                ClearFormBelow(6);
                            }}
                        />}
                </Form.Item>
            </Spin>
        }

        {caseReason &&
            <Spin spinning={subReasonQuery.isRefetching || subReasonQuery.isLoading} className="field-loading-spinner">
                <Form.Item
                    label="Sub Reason"
                    name="subReasonId"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            validator(_rule, val) {
                                if (val !== "") {
                                    if (subReason) {
                                        return Promise.resolve();
                                    }
                                    else {
                                        return Promise.reject("Please select a value");
                                    }
                                }
                                else {
                                    return Promise.reject();
                                }
                            },
                        }
                    ]}
                >
                    {!(subReasonQuery.isRefetching || subReasonQuery.isLoading) &&
                        <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            {...subReasonProps}
                            onChange={(event) => {
                                setSubReason(event.target.value);
                            }}
                        />}
                </Form.Item>
            </Spin>
        }
    </>

    );
}