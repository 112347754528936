import { handleUseParams, IRouterProvider } from "@pankod/refine-core";
import React from "react";
import {
    HashRouterProps, Link,
    RouteProps, useLocation, useNavigate, useParams
} from "react-router-dom";
import { Prompt } from "./prompt";
import {
    HashRouterComponent,
    MemoryRouterComponent
} from "./routerComponent";


export type RefineRouteProps = RouteProps & {
    layout?: boolean;
};
interface IReactRouterProvider extends IRouterProvider {
    useLocation: typeof useLocation;
    Link: typeof Link;
    useParams: any;
    RouterComponent: React.FC<HashRouterProps>;
    routes?: RefineRouteProps[];
}

const hashRouterProvider: IReactRouterProvider = {
    useHistory: () => {
        const navigate = useNavigate();

        return {
            push: navigate,
            replace: (path: string) => {
                navigate(path, { replace: true });
            },
            goBack: () => {
                navigate(-1);
            },
        };
    },
    useLocation,
    useParams: () => {
        const params = useParams();
        const { pathname } = useLocation();
        const paramsString = `/${Object.values(params).join("/")}`;

        return handleUseParams({
            ...params,
            resource:
                Object.keys(params).length === 0
                    ? pathname.substring(1)
                    : decodeURIComponent(pathname).substring(1).replace(paramsString, ""),
        });
    },
    Prompt: Prompt as any,
    Link,
    RouterComponent: HashRouterComponent,
};
export default hashRouterProvider;

export { MemoryRouterComponent };
