import { arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import {
  Button, Checkbox, CreateButton, Drawer, EditButton, ExportButton, FilterDropdownProps,
  getDefaultSortOrder, Icons, Input, List, Select, ShowButton, Space, Table, Tag, TextField, 
  Typography, useCheckboxGroup, useSelect, useTable
} from "@pankod/refine-antd";
import {
  CrudFilters, HttpError, IResourceComponentsProps, useExport, useMany, useNavigation,
  useOne, useUpdateMany
} from "@pankod/refine-core";
import { AppFilterDropdown } from "components/common/AppFilterDropdown";
import { ShowUserCard } from "components/profile/showUserCard";
import { UserFilter } from "components/users/userFilter";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { IBaseProps, ILookup, IUser, IUserFilterVariables, IUserMetadata } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_DEFAULT, DATAPROVIDER_LOOKUP, parseTimeZone, RESOURCE_PATH } from "scripts/site";
import { SelectTransform } from "selecttransform";


/*
export interface IUser extends IBaseProps {
  roleIds: string[];
  userType: string;
  linkedUserIds: string[];
  entityRoleIds: string[];
  lastAccessedAt: string;
  activeUsers: number;
  userTime: string;
  locationId: string;
  managerUserId: string;
  employee: IUserEmployee;
}

export interface IUserEmployee {
  employeeId: number;
  cardNumber: number;
  phoneNumber: string;
  vehicleLicense: string,
  workingFrom: string,
  daysInOffice: string[],
  desk: string,
  alternateEmail: string,
  vehicleMakeModel: string,
  vehicleColor: string,

  buildingAccess: string;
  screeningType: string;
  screeningId: string;
  screeningDate: string | Dayjs;
  hireSource: string;
}
*/
/*
class ExportUser {
  id!: string;
  name!: string;
  roleIds!: string[];
  // cardNumber!: number;
  // phoneNumber!: string;
  // locationId!: string;
  userType!: string;
  managerUserId!: string;
  employeeId!: number;
  screeningType!: string;
  screeningId!: string;
  screeningDate!: string;
  hireSource!: string;
  bulk!: boolean;
  employee!: ExportEmployee;
}

class ExportEmployee {
  employeeId!: number;
  screeningType!: string;
  screeningId!: string;
  screeningDate!: string;
  hireSource!: string;
}
*/

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const { show, list } = useNavigation();
  const { mutate } = useUpdateMany<IBaseProps>();
  const initialFilters: CrudFilters = [{
    field: "name",
    operator: "contains",
    value: ""
  },
  {
    field: "id",
    operator: "contains",
    value: ""
  },
  {
    field: "roleIds",
    operator: "contains",
    value: ""
  }];

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IUser, HttpError, IUserFilterVariables>({
    dataProviderName: DATAPROVIDER_DEFAULT,
    initialSorter: [
      {
        field: "lastAccessedAt",
        order: "desc"
      },
    ],
    initialFilter: initialFilters,
    permanentFilter: [{
      field: "_arrayfields",
      operator: "eq",
      value: "roleIds"
    }],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, userType, state, location, contextualMaster, hobbies, technicalExpertiseAreas, functionalExpertiseAreas } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "userType",
        operator: "eq",
        value: userType
      });
      filters.push({
        field: "locationId",
        operator: "eq",
        value: location
      });
      filters.push({
        field: "stateManager.state",
        operator: "eq",
        value: state
      });
      filters.push({
        field: "employee.contextualMaster",
        operator: "eq",
        value: contextualMaster
      });
      filters.push({
        field: "hobbies ARRAY_CONTAINS",
        operator: "eq",
        value: hobbies
      });
      filters.push({
        field: "technicalExpertiseAreas ARRAY_CONTAINS",
        operator: "eq",
        value: technicalExpertiseAreas
      });
      filters.push({
        field: "functionalExpertiseAreas ARRAY_CONTAINS",
        operator: "eq",
        value: functionalExpertiseAreas
      });
      return filters;
    }
  });

  const defaultOnChange = tableProps?.onChange ?? ((p, f, s, e) => { });

  const role_ids = arrayExtensions.arrayRemoveDuplicate(tableProps?.dataSource?.flatMap((item) => item?.roleIds) ?? []);
  const { data: roleData, isLoading } = useMany<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ROLE,
    ids: role_ids,
    queryOptions: {
      enabled: role_ids.length > 0,
    },
  });


  const { checkboxGroupProps: locationProps } = useCheckboxGroup<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.LOCATION,
    optionLabel: "name",
    optionValue: "id",
    sort: [
      {
        field: "name",
        order: "asc"
      },
    ],
  });

  const { selectProps: roleProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ROLE,
    optionLabel: "name",
    optionValue: "id",
    sort: [
      {
        field: "name",
        order: "asc"
      },
    ],
    fetchSize: 50
  });

  function rowClick(record: IUser) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.USERS, record.id);
      },
    };
  }

  const handleActivation = (id: string, stateAction: string) => {
    mutate({
      resource: RESOURCE_PATH.USERS,
      ids: [id],
      values: { stateAction, id }
    },
      {
        onSuccess: () => {
          list(RESOURCE_PATH.USERS);
        }
      }
    );
  }

  const { data: entityMetadata } = useOne<IUserMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.USERS,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });
  const metaConfig = entityMetadata?.data?.config;
  const [filterOpen, switchFilterForm] = useState(false);
  const exportTransform = new SelectTransform(true);
  //   protected transformReqBody() {
  //     this.originalRequestBody = this.context.req.body;
  //     if (this.originalRequestBody && this.apiDef?.transform) {
  //         const modal = new SelectTransform(true).transformSync(this.apiDef?.transform, this.originalRequestBody, false);
  //         removeTemplateFields(modal);
  //         this.context.req.body = modal;
  //     }
  // }

  // Commenting import export functionality as its not ready to be released. 
  const { triggerExport, isLoading: exportLoading } = useExport<IUser>(
    {
      mapData: (item) => exportTransform.transformSync(metaConfig?.export.transform, item, false), //{
      //   return {
      //     id: item.id,
      //     name: item.name ?? item.id,
      //     roleIds: item.roleIds.join(", "),
      //     locationId: item.locationId ?? "",
      //     managerUserId: item.managerUserId ?? "",

      //     employeeId: item.employee?.employeeId ?? "",
      //     cardNumber: item.employee?.cardNumber ?? "",
      //     phoneNumber: item.employee?.phoneNumber ?? "",
      //     vehicleLicense: item.employee?.vehicleLicense ?? "",
      //     workingFrom: item.employee?.workingFrom ?? "",

      //     desk: item.employee?.desk ?? "",
      //     alternateEmail: item.employee?.alternateEmail ?? "",
      //     vehicleMakeModel: item.employee?.vehicleMakeModel ?? "",
      //     vehicleColor: item.employee?.vehicleColor ?? "",

      //     buildingAccess: item.employee?.buildingAccess ?? ""
      //   };
      //},
      pageSize: metaConfig?.export.pageSize ?? 100,
      filters: [
        {
          field: "userType",
          operator: "ne",
          value: "system"
        }
      ]
    });



  /*
// Commenting import export functionality as its not ready to be released. 
const importProps = useImport<ExportUser>({
  mapData: (item) => {
    if (item && item.id && item.id !== "") {
      const result = new ExportUser();
      result.id = item.id;
      result.name = item.name ?? item.id;
      result.roleIds = ["basicUser"];
      //result.roleIds = result.roleIds.concat(item.roleIds);
      result.userType = "employee";
      result.managerUserId = item.managerUserId;
      result.employee = new ExportEmployee();
      result.employee.screeningType = "HireRight";
      result.bulk = true;

      if (item.employeeId) {
        result.employee.employeeId = +item.employeeId;
      }
      if (item.screeningId) {
        result.employee.screeningId = item.screeningId;
      }
      if (item.screeningDate) {
        result.employee.screeningDate = item.screeningDate;
      }
      if (item.hireSource) {
        result.employee.hireSource = item.hireSource;
      }
      // screeningType!: string;
      // screeningId!: string;
      // screeningDate!: string;
      // hireSource!: string;
      // if (item.cardNumber) {
      //   result.cardNumber = item.cardNumber;
      // }
      // if (item.locationId) {
      //   result.locationId = item.locationId;
      // }
      // if (item.phoneNumber) {
      //   result.phoneNumber = item.phoneNumber;
      // }

      return result;
    }
    return null;
  },
  onFinish: (r) => {
    console.log(r.succeeded);
    console.log(r.errored);
    // const csvExporter = new ExportToCsv({
    //   filename,
    //   useKeysAsHeaders: true,
    //   ...exportOptions,
    // });

    // csvExporter.generateCsv(rawData.map(mapData as any));

    // }
  }
});

*/

  return (
    <List
      pageHeaderProps={{
        title:"Helium Users",
        tags: <Tag color={"default"}>
          {tableQueryResult.isLoading ? "Loading" : tableQueryResult?.data?.total} User{tableQueryResult?.data?.total !== 1 && "s"}
        </Tag>,
        extra: <>
          <CreateButton></CreateButton>
          {/* <ImportButton {...importProps} /> */}
          {metaConfig?.export.enabled && <ExportButton onClick={triggerExport} loading={exportLoading} />}
          <Button type="ghost" key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metaConfig?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
        </>
      }}
    >
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
        }}
        onChange={(p, f, s, e) => {
          initialFilters.forEach(fl => {
            // Set the value to blank string if filter value is undefined.
            // This will avoid filter getting removed from URL and getting reset to equal operator.
            if (fl.operator !== "or" && !f[fl.field]) {
              f[fl.field] = [""];
            }
          });
          defaultOnChange(p, f, s, e);
        }}

      >
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value, record) => <>
            <div>
              <TextField value={value} />
            </div>
            {record?.linkedUserIds?.map((linked: string, i: number) => (<div key={i}>
              <Icons.LinkOutlined style={{ marginRight: 5 }} title="Linked User" />
              <ShowUserCard id={linked} />
            </div>))
            }
          </>}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"

          // Add text filter          
          filterDropdown={(props: FilterDropdownProps) => (
            <AppFilterDropdown {...props} >
              <Input />
            </AppFilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="id"
          key="id"
          onCell={rowClick}
          className="mouseHand"
          title="Email"
          render={(value, record) => <>
            <div>
              <Typography.Text copyable>{value}</Typography.Text>
            </div>
            {record?.linkedUserIds?.map((linked: string, i: number) => (<div key={i}>
              <Icons.LinkOutlined style={{ marginRight: 5 }} title="Linked User" />
              <Typography.Text copyable>{linked}</Typography.Text>
            </div>))
            }
          </>}
          sorter

          // Add text filter
          filterDropdown={(props: FilterDropdownProps) => (
            <AppFilterDropdown {...props} >
              <Input />
            </AppFilterDropdown>
          )}
        />

        <Table.Column
          dataIndex="userType"
          title="User Type"
          onCell={rowClick}
          className="mouseHand"
          sorter
          render={(value) => <TextField value={stringExtensions.capitalize(value)} />}

          filterDropdown={(props) => (
            <AppFilterDropdown {...props}>
              <Checkbox.Group options={metaConfig?.userTypes.map(ut => ({
                label: stringExtensions.capitalize(ut.toString()),
                value: ut.toString()
              }))} />
            </AppFilterDropdown>
          )}
        />

        <Table.Column
          dataIndex="roleIds"
          title="Roles"
          onCell={rowClick}
          className="mouseHand"
          sorter
          render={(value: string[]) => <>
            {isLoading
              ? <TextField value="Loading..." />
              : arrayExtensions.sortByName(roleData?.data || [])
                .filter(r => value?.some(v => r.id === v))
                .map((r, i) => (
                  <div key={i} style={{ marginBottom: 5 }}>
                    <Tag color={r.ui?.color || "blue"}>{r.name || ""}</Tag>
                  </div>
                ))}
          </>}

          filterDropdown={(props) => (
            <AppFilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select Role"
                {...roleProps}
              />
            </AppFilterDropdown>
          )}
        />

        <Table.Column
          dataIndex="locationId"
          title="Location"
          onCell={rowClick}
          className="mouseHand"
          defaultSortOrder={getDefaultSortOrder("locationId", sorter)}
          sorter
          render={(value) => <TextField value={value} />}
          filterDropdown={(props) => (
            <AppFilterDropdown {...props}>
              <Checkbox.Group {...locationProps} />
            </AppFilterDropdown>
          )}
        />
        {/* <Table.Column
          dataIndex="userTime"
          key="userTime"
          title="Time zone"
          render={(value: string) => value
            ? <>
              <Typography.Text title={parseRelativeTimeZone(value)}>{parseTimeZone(value)}</Typography.Text>
            </>
            : ""}
          onCell={rowClick}
          className="mouseHand"
        /> */}
        <Table.Column
          dataIndex="lastAccessedAt"
          key="lastAccessedAt"
          title="Last Seen"
          render={(value: string, userObj: IUser) => <>
            {value && displayRelativeDate(value)}
            {
              userObj.userTime &&
              <><br />
                <Typography.Text style={{ color: "GrayText" }}>{parseTimeZone(userObj.userTime)}</Typography.Text>
              </>
            }
          </>}
          defaultSortOrder={getDefaultSortOrder("lastAccessedAt", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="updatedAt"
          key="updatedAt"
          title="Last Updated"
          render={displayRelativeDate}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column<IUser>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {/* Hidden button to let AuthZ module check access for it */}
              <ShowButton hidden size="small" recordItemId={record.id} />
              <EditButton size="small" recordItemId={record.id} />
              <Button
                size="small"
                onClick={(ev) => { ev.preventDefault(); handleActivation(record.id, record.stateManager.state === "active" ? "inactive" : "active") }}>
                <Icons.UserSwitchOutlined />
                {record.stateManager.state === 'active' ? "De-activate" : "Activate"}
              </Button>
              {/* <DeleteButton size="small" recordItemId={record.id} /> */}
            </Space>
          )}
        />
      </Table>
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Users - Search & Filter"}
        placement="right"
        size="default"
        visible={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <UserFilter formProps={searchFormProps} />
      </Drawer>
    </List>
  );
};
