import { ILookup } from "@architecture-innovation-transformation/lib-common";
import { Form, FormInstance, Input, InputNumber, Radio, Spin, useRadioGroup } from "@pankod/refine-antd";
import { ICase } from "interfaces";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const CaseFieldsTeamRadio: React.FC<{ record?: ICase, isLoading: boolean, form: FormInstance }> = ({ record, isLoading, form }) => {

    const { radioGroupProps: teamProps, queryResult: teamQuery } = useRadioGroup<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.TEAM,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],

    });

    const { radioGroupProps: categoryProps, queryResult: categoryQuery } = useRadioGroup<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.CATEGORY,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
    });

    return (<>
        <Spin spinning={teamQuery.isRefetching || teamQuery.isLoading} className="field-loading-spinner">
            <Form.Item
                label="Team"
                name="teamId"
                rules={[
                    {
                        required: true,
                        whitespace: true,
                    }
                ]}
            >
                <Radio.Group
                    optionType="button"
                    buttonStyle="solid"



                    {...teamProps}
                />
            </Form.Item>
        </Spin>

        <Spin spinning={categoryQuery.isRefetching || categoryQuery.isLoading} className="field-loading-spinner">
            <Form.Item
                label="Category"
                name="categoryId"
                rules={[
                    {
                        required: true,
                        whitespace: true,
                    }
                ]}
            >
                <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    {...categoryProps}
                />
            </Form.Item>
        </Spin>

        <Form.Item
            label="Product"
            name="product"
            rules={[
                {
                    required: false,
                    whitespace: true,
                    max: 100
                },
            ]}
        >
            <Input />
        </Form.Item>


        <Form.Item
            label="Revenue"
            name="revenue"
            rules={[
                {
                    required: true,
                    whitespace: true,
                    type: "number"

                },
            ]}
        >
            <InputNumber addonBefore="$" style={{ width: "100%" }} controls={false} />
        </Form.Item>

        <Form.Item
            label="Partner Name"
            name="partnerName"
            rules={[
                {
                    required: true,
                    whitespace: true,
                    max: 100
                },
            ]}
        >
            <Input />
        </Form.Item>

        <Form.Item
            label="Stakeholder Involved"
            name="stakeholderInvolved"
            rules={[
                {
                    required: true,
                    whitespace: true,
                    max: 100
                },
            ]}
        >
            <Input />
        </Form.Item>
    </>

    );
}