import { Button, Drawer, Icons, Show, Tag } from "@pankod/refine-antd";
import {
  IResourceComponentsProps, useCan, useOne
} from "@pankod/refine-core";
import { OutreachForm, RenderMDFile } from "components/common";
import { IMetadata } from "interfaces";
import React, { useState } from "react";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const About: React.FC<IResourceComponentsProps> = () => {

  const { data: metadata } = useOne<IMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ABOUT,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });

  const metaFields = metadata?.data.fields;
  const aboutUrl = metaFields?.find(f => f.name === "mdPath")?.attachment || null;

  const canListExpertOutreach = useCan({
    resource: RESOURCE_PATH.EXPERT_OUTREACH,
    action: "create"
  });

  const [outreachOpen, switchOutreach] = useState(false);
  return (<Show
    headerProps={{
      title: `About ${process.env.REACT_APP_ApplicationTitle}`,
      tags: <Tag>{process.env.REACT_APP_BuildId}</Tag>,
      extra: canListExpertOutreach.data?.can &&
        <Button type="ghost" key="help" onClick={() => switchOutreach(true)} icon={<Icons.ReadOutlined />}>Feedback</Button>
    }}
  >
    {aboutUrl &&
      <RenderMDFile resourceName={RESOURCE_PATH.ABOUT} mdFilePath={aboutUrl} />
    }
    {canListExpertOutreach.data?.can &&
      <Drawer
        title="Helium Feedback"
        placement="right"
        size="large"
        visible={outreachOpen}
        onClose={() => { switchOutreach(false) }}
      >
        <OutreachForm categoryType="about" />
      </Drawer>
    }

  </Show>);
};
