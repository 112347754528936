import { arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { AccessControlProvider } from "@pankod/refine-core";
import { AAD_LOGIN, allowedResources, CACHE_DURATION, fetchAuthZ, fetchClientPrincipal, getSessionStorageWithExpiry, setSessionStorageWithExpiry, webToApiAction } from "scripts/site";

export const AuthZProvider: AccessControlProvider = {
  can: async ({ resource, action, params }) => {
    const cacheKey = "/authz/" + resource.toLowerCase() + "/" + action.toLowerCase();
    const cache = getSessionStorageWithExpiry(cacheKey);
    if (cache) {
      return cache;
    }

    if (await fetchClientPrincipal()) {
      var isAuthZ = false;
      const apiAction = webToApiAction(action);
      const authZJson = await fetchAuthZ();
      if (arrayExtensions.validateArray(authZJson)) {
        if (allowedResources.some(r => stringExtensions.stringEquals(r, resource))) {
          isAuthZ = true;
        }
        else {
          const matched = authZJson.find(a => stringExtensions.stringEquals(a.id, resource));
          if (matched) {
            const allowResult = matched.allowedActions.some(ac => ac === "*" || stringExtensions.stringEquals(apiAction, ac));
            const denyResult = matched.deniedActions.some(ac => ac === "*" || stringExtensions.stringEquals(apiAction, ac));
            isAuthZ = allowResult && !denyResult;
          }
        }
      }
      const result = isAuthZ ? { can: true } : { can: false, reason: "Unauthorized" };
      setSessionStorageWithExpiry(cacheKey, result, CACHE_DURATION);
      return result;
    } else {
      window.location.href = AAD_LOGIN;
    }
    return { can: false, reason: "Unauthorized" };
  },
};