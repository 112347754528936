import { AuthProvider } from "@pankod/refine-core";
import { fetchClientPrincipal, fetchUserIdentity } from "scripts/site";

export const AuthNProvider: AuthProvider = {
    login: async () => {
        return Promise.resolve();
    },
    logout: () => {
        return Promise.resolve();
    },
    checkError: (error) => {
        if (error.status === 401) { // || error.status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: async () => {
        if (await fetchClientPrincipal()) {
            return Promise.resolve();
        }
        return Promise.reject();
    },
    getPermissions: () => Promise.reject(),
    getUserIdentity: async () => {
        const userJson = await fetchUserIdentity();
        if (userJson) {
            return Promise.resolve(userJson);
        }
        const clientPrincipal = await fetchClientPrincipal();
        if (clientPrincipal) {
            return Promise.resolve({
                id: clientPrincipal.userDetails,
                name: clientPrincipal.userDetails,
            });
        }
        return Promise.reject();
    },
};