import { Button, Icons, Modal } from "@pankod/refine-antd";
import { MermaidView } from "components/common/mermaidView";
import { IState } from "interfaces";
import { statesToMermaid } from "scripts/site";

export const RenderWorkflowModal: React.FC<IState[]> = (entityStates) => {
    function WorkflowModal(dataContent: any) {
        const modal = Modal.info({
            title: 'Workflow ',
            icon: <Icons.ApartmentOutlined />,
            content: dataContent,
            width: '80%',
            className: 'hstrymdl',
            maskClosable: true,
            closable: true,
            onOk() { modal.destroy() }
        });
    }
    return (
        <Button
            className="hst"
            title="View Workflow"
            type="ghost"
            onClick={() => {
                WorkflowModal(
                    <MermaidView placeholderId="hstrymrmadmdl" graphDefinition={statesToMermaid(entityStates)}></MermaidView>
                );
            }}
        >
            <Icons.ApartmentOutlined />
            Workflow
        </Button>
    );
};