import { BackTop, ConfigProvider, Icons, Layout, notificationProvider } from "@pankod/refine-antd";
import { Refine } from "@pankod/refine-core";
import simpleRestDataProvider from "@pankod/refine-simple-rest";
import { aITracking } from "appInsightLoad";
import { AppError, AppFooter, AppHeader, AppTitle } from "components";
import { AppLayout } from "components/common/appLayout";
import { RefineMenu } from "components/common/refineMenu";
import hashRouterProvider from "hashRouter";
import { AuthNProvider, AuthZProvider } from "hooks";
import mermaid from "mermaid";
import mermaidAPI from "mermaid/mermaidAPI";
import { About } from "pages/about/about";
import { AssessmentList } from "pages/assessment";
import { AssetCreate, AssetEdit, AssetList, AssetShow } from "pages/asset";
import { AllocationList } from "pages/associatemapping";
import { AllocationCreate } from "pages/associatemapping/create";
import { CaseCreate, CaseList, CaseShow } from "pages/case";
import { CaseEdit } from "pages/case/edit";
import { ContractEdit, ContractList } from "pages/contracts";
import { EngagementCreate, EngagementEdit, EngagementList, EngagementShow } from "pages/engagements";
import { EvaluationEdit, EvaluationList, EvaluationShow } from "pages/evaluation";
import { EvaluationModelList } from "pages/evaluationmodel/list";
import { EvaluationModelShow } from "pages/evaluationmodel/show";
import { EventCreate, EventEdit, EventList, EventShow } from "pages/events";
import { GroupCreate, GroupEdit, GroupList, GroupShow } from "pages/groups";
import { ABMDeskList, AnnouncementList, AspireList, SecurityInfoList } from "pages/infocorner";
import { Login } from "pages/login";
import { TagsReviewList } from "pages/manage/tags/list";
import { ProfileEdit, ProfileList, Worklist } from "pages/profile";
import { Notifications } from "pages/profile/myNotifications";
import { SystemList } from "pages/system";
import { UserCreate, UserEdit, UserList, UserShow } from "pages/users";
import { validationMessages } from "scripts/messages";
import { API_AUTHZ, API_CREATE, API_CRUD, API_DELETE, API_LOOKUP, API_OUTREACH, API_PATH, API_READ, API_STATE, API_SUBSCRIBE, API_SYSTEM, API_UPDATE, API_UPLOAD, DATAPROVIDER_AUTHZ, DATAPROVIDER_CREATE, DATAPROVIDER_DEFAULT, DATAPROVIDER_DELETE, DATAPROVIDER_LOOKUP, DATAPROVIDER_OUTREACH, DATAPROVIDER_READ, DATAPROVIDER_STATE, DATAPROVIDER_SUBSCRIBE, DATAPROVIDER_SYSTEM, DATAPROVIDER_UPDATE, DATAPROVIDER_UPLOAD, RESOURCE_PATH } from "scripts/site";
import "styles/override.css";

function App() {
  const dataProviders: any = {
    [DATAPROVIDER_DEFAULT]: simpleRestDataProvider(API_PATH + API_CRUD),
    [DATAPROVIDER_READ]: simpleRestDataProvider(API_PATH + API_READ),
    [DATAPROVIDER_CREATE]: simpleRestDataProvider(API_PATH + API_CREATE),
    [DATAPROVIDER_UPDATE]: simpleRestDataProvider(API_PATH + API_UPDATE),
    [DATAPROVIDER_DELETE]: simpleRestDataProvider(API_PATH + API_DELETE),
    [DATAPROVIDER_STATE]: simpleRestDataProvider(API_PATH + API_STATE),
    [DATAPROVIDER_AUTHZ]: simpleRestDataProvider(API_PATH + API_AUTHZ),
    [DATAPROVIDER_LOOKUP]: simpleRestDataProvider(API_PATH + API_LOOKUP),
    [DATAPROVIDER_SYSTEM]: simpleRestDataProvider(API_PATH + API_SYSTEM),
    [DATAPROVIDER_UPLOAD]: simpleRestDataProvider(API_PATH + API_UPLOAD),
    [DATAPROVIDER_SUBSCRIBE]: simpleRestDataProvider(API_PATH + API_SUBSCRIBE),
    [DATAPROVIDER_OUTREACH]: simpleRestDataProvider(API_PATH + API_OUTREACH)
  };

  return (
    <ConfigProvider form={{ validateMessages: { ...validationMessages } }} >
      <Refine
        disableTelemetry
        routerProvider={hashRouterProvider}
        dataProvider={dataProviders}
        authProvider={AuthNProvider}
        LoginPage={Login}
        Title={AppTitle}
        warnWhenUnsavedChanges
        accessControlProvider={AuthZProvider}
        Footer={AppFooter}
        catchAll={AppError}
        Header={AppHeader}
        syncWithLocation
        Sider={RefineMenu}
        OffLayoutArea={() => <BackTop style={{ right: 50 }} />}
        resources={[
          {
            name: "Home",
            options: { label: "Home" },
            icon: <Icons.HomeOutlined />
          },
          {
            name: RESOURCE_PATH.PROFILE,
            list: ProfileList,
            edit: ProfileEdit,
            parentName: "Home",
            options: { label: "My Profile" },
            icon: <Icons.AppstoreOutlined />
          },
          {
            name: RESOURCE_PATH.WORKLIST,
            parentName: "Home",
            list: Worklist,
            options: { label: "My Worklist" },
            icon: <Icons.BellOutlined />
          },
          {
            name: RESOURCE_PATH.NOTIFICATIONS,
            parentName: "Home",
            list: Notifications,
            options: { label: "My Notifications" },
            icon: <Icons.NotificationOutlined />
          },
          {
            name: RESOURCE_PATH.CASE,
            list: CaseList,
            create: CaseCreate,
            edit: CaseEdit,
            show: CaseShow,
            canDelete: true,
            options: { label: "Case" },
            icon: <Icons.ProjectOutlined />
          },
          {
            name: RESOURCE_PATH.ASSET,
            list: AssetList,
            create: AssetCreate,
            edit: AssetEdit,
            show: AssetShow,
            canDelete: true,
            icon: <Icons.LaptopOutlined />
          },
          {
            name: "Engagement",
            options: { label: "Engagement" },
            icon: <Icons.GlobalOutlined />
          },
          {
            name: RESOURCE_PATH.ENGAGEMENTS,
            list: EngagementList,
            show: EngagementShow,
            create: EngagementCreate,
            edit: EngagementEdit,
            parentName: "Engagement",
            canDelete: false,
            icon: <Icons.GlobalOutlined />
          },
          {
            name: RESOURCE_PATH.CONTRACT,
            list: ContractList,
            edit: ContractEdit,
            parentName: "Engagement",
            canDelete: false,
            icon: <Icons.FileProtectOutlined />
          },
          {
            name: RESOURCE_PATH.ASSOCIATEMAPPING,
            list: AllocationList,
            create: AllocationCreate,
            options: { label: "Associates" },
            parentName: "Engagement",
            canDelete: false,
            icon: <Icons.SolutionOutlined />
          },
          {
            name: RESOURCE_PATH.ASSESSMENT,
            list: AssessmentList,
            parentName: "Engagement",
            canDelete: false,
            icon: <Icons.FormOutlined />
          },
          {
            name: RESOURCE_PATH.EVALUATION,
            list: EvaluationList,
            edit: EvaluationEdit,
            show: EvaluationShow,
            parentName: "Engagement",
            canDelete: true,
            icon: <Icons.ContainerOutlined />
          },
          {
            name: RESOURCE_PATH.EVALUATIONMODEL,
            list: EvaluationModelList,
            show: EvaluationModelShow,
            options: { label: "Eval. Models" },
            parentName: "Engagement",
            canDelete: false,
            icon: <Icons.FormOutlined />
          },
          {
            name: RESOURCE_PATH.USERS,
            list: UserList,
            create: UserCreate,
            edit: UserEdit,
            show: UserShow,
            canDelete: true,
            icon: <Icons.TeamOutlined />
          },
          {
            name: RESOURCE_PATH.GROUPS,
            list: GroupList,
            create: GroupCreate,
            edit: GroupEdit,
            show: GroupShow,
            canDelete: true,
            icon: <Icons.GroupOutlined />
          },
          {
            name: RESOURCE_PATH.EVENT,
            options: { label: "Events" },
            list: EventList,
            create: EventCreate,
            edit: EventEdit,
            show: EventShow,
            canDelete: true,
            icon: <Icons.ClusterOutlined />
          },
          {
            name: "info-board",
            options: { label: "Info Corner" },
            icon: <Icons.InfoCircleOutlined />
          },
          {
            name: RESOURCE_PATH.INFO_ABMDESK,
            list: ABMDeskList,
            parentName: "info-board",
            options: { label: "ABM Desk" },
            icon: <Icons.CommentOutlined />
          },
          {
            name: RESOURCE_PATH.INFO_ANNOUNCEMENTS,
            list: AnnouncementList,
            parentName: "info-board",
            options: { label: "Announcements" },
            icon: <Icons.SoundOutlined />
          },
          {
            name: RESOURCE_PATH.INFO_ASPIRE,
            list: AspireList,
            parentName: "info-board",
            options: { label: "ASPIRE" },
            icon: <Icons.ReadOutlined />
          },
          {
            name: RESOURCE_PATH.INFO_SECURITY,
            list: SecurityInfoList,
            parentName: "info-board",
            options: { label: "Security" },
            icon: <Icons.SecurityScanOutlined />
          },
          {
            name: RESOURCE_PATH.SYSTEM,
            list: SystemList,
            options: { label: "System" },
            canDelete: false,
            icon: <Icons.CodeOutlined />
          },
          {
            name: "manage",
            options: { label: "Manage" },
            icon: <Icons.SettingOutlined />
          },
          {
            name: RESOURCE_PATH.REVIEW_TAGS,
            list: TagsReviewList,
            parentName: "manage",
            options: { label: "Review Tags" },
            canDelete: false,
            icon: <Icons.VerifiedOutlined />
          },
          {
            name: RESOURCE_PATH.ABOUT,
            list: About,
            canDelete: false,
            options: { label: "About" },
            icon: <Icons.InfoOutlined />
          },

        ]}
        notificationProvider={notificationProvider}
        reactQueryClientConfig={{
          defaultOptions: {
            queries: {
              retry: false
            },
          },
        }}
        //Layout={Layout}
        Layout={AppLayout}
      >
      </Refine>
    </ConfigProvider>
  );
}

export const lay = aITracking(Layout);
mermaid.initialize({ startOnLoad: false, theme: "dark" as mermaidAPI.Theme, securityLevel: "loose" as mermaidAPI.SecurityLevel })
export default App;