import { Space, Tooltip, Typography } from "@pankod/refine-antd";
import { clearLocalSessionStorage, HELIUM_LOGO_PATH } from "scripts/site";

export const AppTitle: React.FC = (params: any) => {
    return (
        <Tooltip title="Reload">
            <a href="/" onClick={() => clearLocalSessionStorage(false)}>
                <Space>
                    <img
                        src={HELIUM_LOGO_PATH} alt={process.env.REACT_APP_ApplicationTitle}
                        className="app-title-small"
                    />
                    {params.collapsed
                        ? <></>
                        : <Typography.Text className="app-title">
                            Helium for Services
                        </Typography.Text>
                    }
                </Space>
            </a>
        </Tooltip>
    );
};