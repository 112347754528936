import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import {
  Button, CreateButton, Drawer, EditButton, getDefaultSortOrder, Icons, List, ShowButton, Space, Table,
  Tag, TextField, Typography, useTable
} from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity, useNavigation, useOne } from "@pankod/refine-core";
import { GroupsFilter } from "components/groups/groupsFilter";
import { ShowUserCard } from "components/profile/showUserCard";
import { IGroupsMetadata, IMemberGroup, IUser } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH } from "scripts/site";

export const GroupList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const { data: metadata } = useOne<IGroupsMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.GROUPS,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IMemberGroup, HttpError, { q: string, state: string, groupType: string }>({
    dataProviderName: DATAPROVIDER_READ,
    initialSorter: [
      {
        field: "name",
        order: "asc"
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, state, groupType } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "groupType",
        operator: "eq",
        value: groupType
      });
      filters.push({
        field: "stateManager.state",
        operator: "eq",
        value: state
      });
      return filters;
    }
  });

  function rowClick(record: IMemberGroup) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.GROUPS, record.id);
      },
    };
  }

  const { data: userData } = useGetIdentity();
  const userObj = userData as IUser;
  const totalGroups = tableQueryResult?.data?.total ?? 0;
  const [filterOpen, switchFilterForm] = useState(false);
  const metaConfig = metadata?.data.config;

  return (
    <List
      title={<>
        {`Helium Groups `}
        <Tag color={"default"}>
          {tableQueryResult.isLoading ? "Loading" : totalGroups} Group{totalGroups !== 1 && "s"}
        </Tag>
      </>}
      headerButtons={() => (
        <>
          <CreateButton></CreateButton>
          <Button type="ghost" key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metaConfig?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
        </>
      )}
    >
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
        }}
      >
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value) =>
            <div>
              <TextField value={value} />
            </div>
          }
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />
        <Table.Column
          dataIndex="groupType"
          title="Group Type"
          onCell={rowClick}
          className="mouseHand"
          sorter
          render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
        />
        <Table.Column
          dataIndex="admins"
          title="Group Admins"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string[]) =>
            value && value.map((r, i) => (
              <div key={i} style={{ marginBottom: 5 }}>
                <ShowUserCard id={r} />
              </div>
            ))
          }
        />
        <Table.Column
          dataIndex="createdBy"
          title="Created By"
          onCell={rowClick}
          className="mouseHand"
          defaultSortOrder={getDefaultSortOrder("locationId", sorter)}
          sorter
          render={(value) => <ShowUserCard id={value} />}
        />

        <Table.Column<IMemberGroup>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {/* Hidden button to let AuthZ module check access for it */}
              <ShowButton hidden size="small" recordItemId={record.id} />
              {record.admins.find(x => x === userObj.id) &&
                <EditButton size="small" recordItemId={record.id} />
              }
            </Space>
          )}
        />
      </Table>
      <Drawer
        title={metadata?.data?.config?.search?.textMessages?.drawerFormHeader || "Groups - Search & Filter"}
        placement="right"
        size="default"
        visible={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <GroupsFilter formProps={searchFormProps} />
      </Drawer>
    </List>
  );
};
