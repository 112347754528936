//Timeline to replace engagement/historyModal

import { Button, Icons, Modal, useModal } from "@pankod/refine-antd";
import { IStateManager } from "interfaces";
import { HistoryTab } from "./historyTab";


export const RenderTimelineModal: React.FC<IStateManager> = (stateManager) => {
    const { modalProps, show, close } = useModal()

    return (
        <>
            <Button
                className="hst"
                title="View Workflow"
                type="ghost"
                onClick={show}
            >
                <Icons.HistoryOutlined />
                Workflow
            </Button>
            <Modal
                {...modalProps}
                footer={
                    <Button type="primary" key="back" onClick={close}>
                        Return
                    </Button>
                }
                width='80%'
            >
                <HistoryTab
                    stateManager={stateManager}
                    tabPosition={'top'}
                />
            </Modal>
        </>
    );
};