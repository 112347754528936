import { Button, ButtonProps, Icons } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";

export const CancelButton: React.FC<ButtonProps> = ({
    children,
    ...rest
}) => {
    const { goBack } = useNavigation();
    return (
        <Button onClick={goBack} icon={<Icons.CloseOutlined />} {...rest}>
            {children ?? "Cancel"}
        </Button>
    );
};