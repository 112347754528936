const lightTheme = {
    '--body-background' : '#fff',
    '--background-color-base': '#f5f5f5',
    '--component-background': '#fff',
    '--icon-color-hover': 'rgba(0, 0, 0, 0.75)',
    '--heading-color': 'rgba(0, 0, 0, 0.85)',
    '--text-color-secondary': 'rgba(0, 0, 0, 0.45)',
    '--disabled-color': 'rgba(0, 0, 0, 0.25)',
    '--skeleton-color': 'rgba(0, 0, 0, 0.06)',
    '--input-placeholder-color': '#bfbfbf',
    '--input-bg': '#fff',
    '--input-disabled-shade1': 'rgba(0, 0, 0, 0.2)',
    '--input-disabled-shade2': 'rgba(0, 0, 0, 0.1)',
    '--input-disabled-radio-check': '#e6e6e6',
    '--border-color-base': '#d9d9d9',
    '--select-multiple-item-disabled-color':'#bfbfbf',
    '--select-multiple-item-disabled-border-color':'#d9d9d9',
    '--select-dropdown-bg-color': '#fff',
    '--shadow-color': 'rgba(0, 0, 0, 0.15)',
    '--shadow-1-up': '0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03)',
    '--shadow-1-down': '0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03)',
    '--shadow-1-left':'6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03)',
    '--shadow-1-right': '',
    '--shadow-2': '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
    '--shadow-3': '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)',
    // Empty and Empty image - theme colors
    '--empty-img-ellipse-fill':'#f5f5f5',
    '--empty-img-ellipse-fill-opacity':'0.8',
    '--empty-img-default-path1-fill':'#aeb8c2',
    '--empty-img-default-path3-fill':'#f5f5f7',
    '--empty-img-default-path4-fill':'#dce0e6',
    '--empty-img-default-path5-fill':'#dce0e6',
    '--empty-img-default-g-fill': '#fff',
    '--empty-img-simple-ellipse-fill': '#f5f5f5',
    '--empty-img-simple-ellipse-opacity': '1',
    '--empty-img-simple-g-stroke':'#d9d9d9',
    '--empty-img-simple-path-fill':'#fafafa',
    '--empty-img-simple-path-stroke':'#fafafa',
    // Avtar
    '--avatar-bg': '#ccc',
    // Popover preset colors
    '--popover-pink-bg-color':'#eb2f96',
    '--popover-magenta-bg-color':'#eb2f96',
    '--popover-red-bg-color':'#f5222d',
    '--popover-volcano-bg-color':'#fa541c',
    '--popover-orange-bg-color':'#fa8c16',
    '--popover-yellow-bg-color':'#fadb14',
    '--popover-gold-bg-color':'#faad14',
    '--popover-cyan-bg-color':'#13c2c2',
    '--popover-lime-bg-color':'#a0d911',
    '--popover-green-bg-color':'#52c41a',
    '--popover-blue-bg-color':'#1890ff',
    '--popover-geekblue-bg-color':'#2f54eb',
    '--popover-purple-bg-color':'#722ed1',
    // Badge
    '--badge-bg-color' : '#ff4d4f',
    // Menu Item
    '--menu-highlight-danger-color': '#fff1f0',
    '--menu-inline-bg-color' : '#fafafa',
    '--menu-dark-horizontal-item' : '#f9f9f9',
    '--header-horizontal-item-bg-color': '#f9f9f9',
    '--header-user-name-color': '#000',
    // Buttons
    '--btn-text-focus-bg-color' : 'rgba(0, 0, 0, 0.018)',
    '--btn-text-active-bg-color': 'rgba(0, 0, 0, 0.028)',
    // Tag Colors
    '--tag-pink-color': '#c41d7f',
    '--tag-pink-bg-color': '#fff0f6',
    '--tag-pink-border-color': '#ffadd2',
    '--tag-pink-inverse-bg-color': '#eb2f96',
    '--tag-magenta-color': '#c41d7f',
    '--tag-magenta-bg-color': '#fff0f6',
    '--tag-magenta-border-color': '#ffadd2',
    '--tag-magenta-inverse-bg-color': '#eb2f96',
    '--tag-red-color': '#cf1322',
    '--tag-red-bg-color': '#fff1f0',
    '--tag-red-border-color': '#ffa39e',
    '--tag-red-inverse-bg-color': '#f5222d',
    '--tag-volcano-color': '#d4380d',
    '--tag-volcano-bg-color': '#fff2e8',
    '--tag-volcano-border-color': '#ffbb96',
    '--tag-volcano-inverse-bg-color': '#fa541c',
    '--tag-orange-color': '#d46b08',
    '--tag-orange-bg-color': '#fff7e6',
    '--tag-orange-border-color': '#ffd591',
    '--tag-orange-inverse-bg-color': '#fa8c16',
    '--tag-yellow-color': '#d4b106',
    '--tag-yellow-bg-color': '#feffe6',
    '--tag-yellow-border-color': '#fffb8f',
    '--tag-yellow-inverse-bg-color': '#fadb14',
    '--tag-gold-color': '#d48806',
    '--tag-gold-bg-color': '#fffbe6',
    '--tag-gold-border-color': '#ffe58f',
    '--tag-gold-inverse-bg-color': '#faad14',
    '--tag-cyan-color': '#08979c',
    '--tag-cyan-bg-color': '#e6fffb',
    '--tag-cyan-border-color': '#87e8de',
    '--tag-cyan-inverse-bg-color': '#13c2c2',
    '--tag-lime-color': '#7cb305',
    '--tag-lime-bg-color': '#fcffe6',
    '--tag-lime-border-color': '#eaff8f',
    '--tag-lime-inverse-bg-color': '#a0d911',
    '--tag-green-color': '#389e0d',
    '--tag-green-bg-color': '#f6ffed',
    '--tag-green-border-color': '#b7eb8f',
    '--tag-green-inverse-bg-color': '#52c41a',
    '--tag-blue-color': '#096dd9',
    '--tag-blue-bg-color': '#e6f7ff',
    '--tag-blue-border-color': '#91d5ff',
    '--tag-blue-inverse-bg-color': '#1890ff',
    '--tag-geekblue-color': '#1d39c4',
    '--tag-geekblue-bg-color': '#f0f5ff',
    '--tag-geekblue-border-color': '#adc6ff',
    '--tag-geekblue-inverse-bg-color': '#2f54eb',
    '--tag-purple-color': '#531dab',
    '--tag-purple-bg-color': '#f9f0ff',
    '--tag-purple-border-color': '#722ed1',
    '--tag-purple-inverse-bg-color': '#642ab5',
    // Skeleton
    '--skeleton-gradient-color': 'rgba(129, 129, 129, 0.24)',
    //Comment
    '--comment-bg-color': 'inherit',
    '--comment-actions-hover-color': '#595959',
    // Divider seperator color
    '--divider-color' : 'rgba(0, 0, 0, 0.06)',
    //Layout
    '--layout-trigger-bg-color' : '#f9f9f9',
    '--layout-trigger-color' : '#000',
    //Pagination
    '--pagination-disabled-active' :'#e6e6e6',
    '--page-header-back-button-color': '#000',
    //Segmentation
    '--segmented-item-selected-bg-color': '#fff',
    '--slider-rail-bg-color' : '#f5f5f5',
    //Tables
    '--table-cell-scrollbar-color': '#fafafa',
    '--table-cell-row-hover-bg-color': '#fafafa',
    '--table-cell-hover-color': '#f5f5f5',
    '--table-column-sort-bg-color': '#f5f5f5',
    '--table-column-sort-color': '#fafafa',
    '--table-dropdown-btns-bg-color' : '#inherit',
    '--table-sticky-scroll-bg-color' : 'rgba(204, 204, 204, 0.06)',
    // Helium Overrides
    '--signin-btn-bg-color': '#663399',
    '--signin-btn-focus-color': '#7C51A8',
    '--app-title-color': '#663399',
    '--tmlncardttl-ant-card-head-bg-color':'transparent',
    '--tmlncardttl-card-head-link-color': '#000',
    '--navbtn-bg-color': '#fafafa',
    '--navbtn-border-color': '#f0f0f0',
    '--contracttable-bg-color' :'#fbfbfb',
    '--tooltip-inner-color': '#000',
    '--info-color-bg' : '#fff',
    '--mde-btn-color': '#242729'
  };
  const darkTheme = {
    '--body-background' : '#000',
    '--background-color-base': 'rgba(255, 255, 255, 0.08)',
    '--component-background': '#141414',
    '--icon-color-hover': 'rgba(255, 255, 255, 0.75)',
    '--heading-color': 'rgba(255, 255, 255, 0.85)',
    '--text-color-secondary': 'rgba(255, 255, 255, 0.45)',
    '--disabled-color': 'rgba(255, 255, 255, 0.3)',
    '--skeleton-color': '#303030',
    '--input-placeholder-color': 'rgba(255, 255, 255, 0.3)',
    '--input-bg': 'transparent',
    '--input-disabled-shade1': 'rgba(255, 255, 255, 0.2)',
    '--input-disabled-shade2': 'rgba(255, 255, 255, 0.1)',
    '--input-disabled-radio-check': 'rgba(255, 255, 255, 0.2)',
    '--border-color-base': '#434343',
    '--select-multiple-item-disabled-color':'#595959',
    '--select-multiple-item-disabled-border-color':'#1f1f1f',
    '--select-dropdown-bg-color': '#1f1f1f',
    '--shadow-color': 'rgba(0, 0, 0, 0.45)',
    '--shadow-1-up': '0 6px 16px -8px rgba(0, 0, 0, 0.32), 0 9px 28px 0 rgba(0, 0, 0, 0.2), 0 12px 48px 16px rgba(0, 0, 0, 0.12)',
    '--shadow-1-down': '0 -6px 16px -8px rgba(0, 0, 0, 0.32), 0 -9px 28px 0 rgba(0, 0, 0, 0.2), 0 -12px 48px 16px rgba(0, 0, 0, 0.12)',
    '--shadow-1-left':'6px 0 16px -8px rgba(0, 0, 0, 0.32), 9px 0 28px 0 rgba(0, 0, 0, 0.2), 12px 0 48px 16px rgba(0, 0, 0, 0.12)',
    '--shadow-1-right': '6px 0 16px -8px rgba(0, 0, 0, 0.24), 9px 0 28px 0 rgba(0, 0, 0, 0.15), 12px 0 48px 16px rgba(0, 0, 0, 0.09)',
    '--shadow-2': '0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2)',
    '--shadow-3': '0 1px 2px -2px rgba(0, 0, 0, 0.64), 0 3px 6px 0 rgba(0, 0, 0, 0.48), 0 5px 12px 4px rgba(0, 0, 0, 0.36)',
    // Empty and Empty image - theme colors
    '--empty-img-ellipse-fill':'#fff',
    '--empty-img-ellipse-fill-opacity':'0.08',
    '--empty-img-default-path1-fill':'#262626',
    '--empty-img-default-path3-fill':'#595959',
    '--empty-img-default-path4-fill':'#434343',
    '--empty-img-default-path5-fill':'#595959',
    '--empty-img-default-g-fill': '#434343',
    '--empty-img-simple-ellipse-fill': '#fff',
    '--empty-img-simple-ellipse-opacity': '0.08',
    '--empty-img-simple-g-stroke':'#434343',
    '--empty-img-simple-path-fill':'#262626',
    '--empty-img-simple-path-stroke':'#262626',
    // Avtar
    '--avatar-bg': 'rgba(255, 255, 255, 0.3)',
    // Popover preset colors
    '--popover-pink-bg-color':'#cb2b83',
    '--popover-magenta-bg-color':'#cb2b83',
    '--popover-red-bg-color':'#d32029',
    '--popover-volcano-bg-color':'#d84a1b',
    '--popover-orange-bg-color':'#d87a16',
    '--popover-yellow-bg-color':'#d8bd14',
    '--popover-gold-bg-color':'#d89614',
    '--popover-cyan-bg-color':'#13a8a8',
    '--popover-lime-bg-color':'#49aa19',
    '--popover-green-bg-color':'#177ddc',
    '--popover-blue-bg-color':'#1890ff',
    '--popover-geekblue-bg-color':'#2b4acb',
    '--popover-purple-bg-color':'#642ab5',
    // Badge
    '--badge-bg-color' : '#a61d24',
    // Menu Item
    '--menu-highlight-danger-color': '#2a1215',
    '--menu-inline-bg-color' : 'rgba(255, 255, 255, 0.04)',
    '--menu-dark-horizontal-item' : '#1f1f1f',
    '--header-horizontal-item-bg-color': '#1f1f1f',
    '--header-user-name-color': '#fff',
    // Buttons
    '--btn-text-focus-bg-color' : 'rgba(255, 255, 255, 0.03)',
    '--btn-text-active-bg-color': 'rgba(255, 255, 255, 0.04)',
    // Tag Colors
    '--tag-pink-color': '#e0529c',
    '--tag-pink-bg-color': '#291321',
    '--tag-pink-border-color': '#551c3b',
    '--tag-pink-inverse-bg-color': '#cb2b83',
    '--tag-magenta-color': '#e0529c',
    '--tag-magenta-bg-color': '#291321',
    '--tag-magenta-border-color': '#551c3b',
    '--tag-magenta-inverse-bg-color': '#cb2b83',
    '--tag-red-color': '#e84749',
    '--tag-red-bg-color': '#2a1215',
    '--tag-red-border-color': '#58181c',
    '--tag-red-inverse-bg-color': '#d32029',
    '--tag-volcano-color': '#e87040',
    '--tag-volcano-bg-color': '#2b1611',
    '--tag-volcano-border-color': '#592716',
    '--tag-volcano-inverse-bg-color': '#d84a1b',
    '--tag-orange-color': '#e89a3c',
    '--tag-orange-bg-color': '#2b1d11',
    '--tag-orange-border-color': '#593815',
    '--tag-orange-inverse-bg-color': '#d87a16',
    '--tag-yellow-color': '#e8d639',
    '--tag-yellow-bg-color': '#2b2611',
    '--tag-yellow-border-color': '#595014',
    '--tag-yellow-inverse-bg-color': '#d8bd14',
    '--tag-gold-color': '#e8b339',
    '--tag-gold-bg-color': '#2b2111',
    '--tag-gold-border-color': '#594214',
    '--tag-gold-inverse-bg-color': '#d89614',
    '--tag-cyan-color': '#33bcb7',
    '--tag-cyan-bg-color': '#112123',
    '--tag-cyan-border-color': '#144848',
    '--tag-cyan-inverse-bg-color': '#13a8a8',
    '--tag-lime-color': '#a9d134',
    '--tag-lime-bg-color': '#1f2611',
    '--tag-lime-border-color': '#3e4f13',
    '--tag-lime-inverse-bg-color': '#8bbb11',
    '--tag-green-color': '#6abe39',
    '--tag-green-bg-color': '#162312',
    '--tag-green-border-color': '#274916',
    '--tag-green-inverse-bg-color': '#49aa19',
    '--tag-blue-color': '#3c9ae8',
    '--tag-blue-bg-color': '#111d2c',
    '--tag-blue-border-color': '#15395b',
    '--tag-blue-inverse-bg-color': '#177ddc',
    '--tag-geekblue-color': '#5273e0',
    '--tag-geekblue-bg-color': '#131629',
    '--tag-geekblue-border-color': '#1c2755',
    '--tag-geekblue-inverse-bg-color': '#2b4acb',
    '--tag-purple-color': '#854eca',
    '--tag-purple-bg-color': '#1a1325',
    '--tag-purple-border-color': '#301c4d',
    '--tag-purple-inverse-bg-color': '#642ab5',
    // Skeleton
    '--skeleton-gradient-color': 'rgba(255, 255, 255, 0.16)',
    //Comment
    '--comment-bg-color': 'transparent',
    '--comment-actions-hover-color': 'rgba(255, 255, 255, 0.65)',
    // Divider seperator color
    '--divider-color' : 'rgba(255, 255, 255, 0.12)',
    //Layout
    '--layout-trigger-bg-color' : '#262626',
    '--layout-trigger-color' : '#fff',
    //Pagination
    '--pagination-disabled-active' :'rgba(255, 255, 255, 0.25)',
    '--page-header-back-button-color': 'inherit',
    //Segmentation
    '--segmented-item-selected-bg-color': '#333333',
    '--slider-rail-bg-color' : '#262626',
    //Tables
    '--table-cell-scrollbar-color': '#1d1d1d',
    '--table-cell-row-hover-bg-color': '#262626',
    '--table-cell-hover-color': '#222',
    '--table-column-sort-bg-color': '#262626',
    '--table-column-sort-color': 'rgba(255, 255, 255, 0.01)',
    '--table-dropdown-btns-bg-color' : '#1f1f1f',
    '--table-sticky-scroll-bg-color' : '#fcfcfc',
    // Helium Overrides
    '--signin-btn-bg-color': '#663399',
    '--signin-btn-focus-color': '#7C51A8',
    '--app-title-color': '#663399',
    '--tmlncardttl-ant-card-head-bg-color':'#222',
    '--tmlncardttl-card-head-link-color': '#eee',
    '--navbtn-bg-color': '#141414',
    '--navbtn-border-color': '#303030',
    '--contracttable-bg-color' :'#000',
    '--tooltip-inner-color': '#fff',
    '--info-color-bg' : '#111b26',
    '--mde-btn-color': '#fff'
  };

  export const applyTheme = nextTheme => {
    const theme = nextTheme === "dark" ? darkTheme : lightTheme;
    Object.keys(theme).forEach(key => {
      const value = theme[key];
      document.documentElement.style.setProperty(key, value);
    });
  };